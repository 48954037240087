// This is an auto generated file, if you wan't to make changes please make them in the config/locale
import I18n from 'i18n-js';

I18n.translations || (I18n.translations = {});
I18n.translations["en"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "was already confirmed, please try signing in",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "expired": "has expired, please request a new one",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "content_type_invalid": "has an invalid content type",
      "file_size_out_of_range": "size %{file_size} is not between required range",
      "limit_out_of_range": "total number is out of range",
      "image_metadata_missing": "is not a valid image",
      "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
      "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
      "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
      "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
      "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
      "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
      "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
      "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
      "dimension_width_equal_to": "width must be equal to %{length} pixel",
      "dimension_height_equal_to": "height must be equal to %{length} pixel",
      "aspect_ratio_not_square": "must be a square image",
      "aspect_ratio_not_portrait": "must be a portrait image",
      "aspect_ratio_not_landscape": "must be a landscape image",
      "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
      "aspect_ratio_unknown": "has an unknown aspect ratio"
    },
    "reset_password_token_invalid": "Reset password token is invalid"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": "User",
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "Dashboard"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "Admin"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "Only visible for admin",
    "visible_for_admin_text": "This item is not published yet and only admins can preview this item"
  },
  "essential_card": {
    "resume": "Resume",
    "start": "Start",
    "completed": "Completed"
  },
  "global": {
    "back": "Back",
    "exit": "Exit",
    "min": "min",
    "favorite": "Add to favorites",
    "remove_favorite": "Remove from favorites",
    "next": "Next",
    "check": "Check",
    "save": "Save",
    "assign_content": "Assign content",
    "assign_tags": "Assign tags",
    "sign_off_training": "Sign off training",
    "sign_off_list_title": "Class room and Assignment Essentials",
    "search_content": "Search content",
    "search_tags": "Search tags",
    "search_training": "Search training",
    "already_completed": "%{item} already completed!"
  },
  "unlock_by_completing": "Unlock by completing",
  "layout": {
    "header": {
      "navigation": {
        "home": "Home",
        "profile": "Profile",
        "favorites": "Favorites",
        "learn_more": "Learn more",
        "management_report": "Management report"
      }
    },
    "footer": {
      "feedback": "Feedback"
    }
  },
  "impersonate": {
    "logged_in_as": "Logged in as: %{name}",
    "sign_out": "Sign out"
  },
  "perform": {
    "title": "Your progress",
    "points": "Points",
    "position": "Your position",
    "achievements": "Your achievements",
    "give_retries": "Give retries",
    "give_retry": {
      "button": "Give retry",
      "search": "Search learning paths",
      "subtitle": "Search for learning paths that this user doesn't have any retries on"
    },
    "manager": {
      "subtitle": "Management report",
      "title": "Performance",
      "averages": "Averages",
      "position": "Position",
      "achievements": "Achievements",
      "progress": "Progress",
      "chart": {
        "progress": "Learning Paths Progress",
        "knowledge_checks": "Knowledge check scores",
        "experience": "XP points"
      },
      "report": {
        "title": "Download report",
        "button": "Export to csv"
      },
      "table": {
        "employees": "Employees",
        "points": "XP points",
        "progress": "Progress",
        "knowledge_checks": "Knowledge checks average",
        "badges": "Badges",
        "groupless_users": "Not in a group (%{count})",
        "grant_retry": "Give retry",
        "granted_retry": "You've given %{name} a retry for the knowledge check of '%{learning_path_name}'",
        "granted_retry_error": "Giving retries is not possible"
      }
    }
  },
  "learn_more": {
    "title": "Learn more",
    "subtitle": "The essentials",
    "no_items": "There are no items within your search criteria",
    "all": "All",
    "search": "Search",
    "filters": {
      "assigned": "Assigned to you",
      "mandatory": "Mandatory",
      "tags": "Based on your tags"
    }
  },
  "bookmark": {
    "title": "Favorites",
    "subtitle": "Your saved items",
    "load_more": "Load more",
    "no_bookmarks_html": "You currently don't have any favorites, click on the %{icon} to favorite an article to create your own personal library with easy to find articles."
  },
  "homepage": {
    "header": {
      "your_performance": "Your performance",
      "no_assigned": "No assigned content",
      "no_assigned_explanation": "You currently don't have any assigned content that you haven't completed yet, go to the learn more page to explore all the content."
    },
    "assigned": {
      "title": "Assigned to you",
      "button": "Learn more"
    },
    "tag": {
      "button": "View"
    },
    "favorites": {
      "title": "My favorites",
      "button": "View all"
    }
  },
  "essential": {
    "no_unlockers_html": "There a currently no items available which allow you to unlock this essential. Please contact your site admin for assistance",
    "intro": {
      "start": "Start learning",
      "no_content": "This essential has no chapter items and can therefore not be started."
    },
    "general": {
      "correct": "Correct",
      "incorrect": "Incorrect"
    },
    "conclusion": {
      "end": "You've reached",
      "exit": "The end"
    },
    "class_room": {
      "title": "Classroom training",
      "reset": "Reset training"
    },
    "assignment": {
      "title": "Assignment"
    },
    "self_study": {
      "title": "Self Study"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "Learning path"
    },
    "learningpaths": "Learning paths",
    "title": "Learning path",
    "no_unlockers_html": "There a currently no items available which allow you to unlock this learning path. Please contact your site admin for assistance",
    "knowledge_check": "Knowledge check",
    "essentials": "Essentials",
    "essential_add": "You can search here for essentials to add to your selected essential group.",
    "essentials_message": "Complete the challenge to unlock essentials",
    "tags": "Tags",
    "see_results": "See results",
    "results": {
      "averages": "Averages",
      "learning_path_progress": "Learning path progress",
      "knowledge_check_scores": "Knowledge check scores",
      "knowledge_check_results": "Knowledge check results",
      "xp_points": "Xp points",
      "details": "Details",
      "detailed": {
        "question": "Question",
        "correct_answer": "Correct answer",
        "correct_answers": "Correct answers",
        "correct_answers_amount": "Correct answers amount",
        "incorrect_answers": "Incorrect answers",
        "incorrect_answers_amount": "Incorrect answers amount",
        "no_results": "No results",
        "current_questions": "Current questions",
        "historical_questions": "Historical questions"
      },
      "groups": {
        "name": "Group name",
        "learning_path_progress": "Learning path progress",
        "first_attempt": "First attempt",
        "last_attempt": "Last attempt",
        "achieved_learning_path": "Achieved learning path",
        "not_applicable": "N/A",
        "groupless": "Groupless"
      }
    }
  },
  "badge": {
    "title": "Achievement unlocked!",
    "points": "+%{score} points"
  },
  "download_list": {
    "download_specifications": "Download the specifications",
    "download": "Download"
  },
  "challenge": {
    "intro": {
      "title_html": "Challenge",
      "test": "Test it in the Challenge!",
      "explanation_html": "Are you ready? Try to answer these questions as well as you can. You only have one shot. The more questions you answer correctly, the more essentials will be marked as completed.<br>But there’s more… <br>You will receive 100 XP points for every correct answer. Check out your profile page and see how you rank compared to your colleagues!",
      "ready_set_go": "Ready… Set… Go!",
      "already_completed": "Continue to study the essentials.",
      "retries": {
        "zero": "You have no attempts left",
        "one": "You have one attempt!",
        "other": "You have %{count} attempts!"
      }
    },
    "button": {
      "continue": "Continue challenge",
      "start": "Start challenge"
    },
    "circle-chart": {
      "questions": "Questions"
    },
    "completed": {
      "nice_try": "Nice try!",
      "good_job": "Good job!",
      "failed_html": "You still have somethings to learn, so no essentials have been completed.<br>Explore and study the following essentials:",
      "perfect_score": "You have achieved a perfect score on the following topics:",
      "marked_complete": "These topics have been marked complete but feel free to study them anyway.",
      "answered_correctly_html": "You have answered <b>%{correct}</b> out of <b>%{total}</b> answers correctly.",
      "circle-chart": {
        "your-score": "Your score"
      },
      "button": {
        "close": "Close challenge",
        "try-again": "Try again"
      }
    },
    "question": {
      "helper_multiple_choice": "Choose the right answer",
      "helper_multiple_response": "Multiple answers possible",
      "question": "Question"
    },
    "points": "xp points"
  },
  "knowledge_check": {
    "attempts": {
      "first": "first attempt",
      "many": "attempt %{count}",
      "previous": "previous attempt",
      "last": "last attempt"
    },
    "intro": {
      "title_html": "Knowledge check",
      "test": "Test your knowledge in the knowledge check!",
      "explanation_html": "You have learned a lot, let’s see what you know. This knowledge check measures your knowledge on this learning path.<br><br>If you answer 80% of the questions correctly, the learning path is marked as complete and you will receive a trophy.<br>Don’t forget… you get 100 XP points for each correct answer.",
      "already_completed": "Check out the next learning path!"
    },
    "button": {
      "continue": "Continue knowledge check",
      "start": "Start knowledge check"
    },
    "completed": {
      "good_job": "Good job!",
      "nice_try": "Too bad...",
      "passed_check_html": "Great! You have passed this knowledge check by scoring <b>%{percentage}%</b>.<br> Check out the next learning path!",
      "try_again": {
        "title": "Try again",
        "text_html": "Unfortunately, you have scored <b>%{percentage}%</b> and did not pass this knowledge check.<br>There's only one attempt left; use it wisely...",
        "review": "Please review all essentials in the learning path before re-taking the knowledge check.",
        "good_luck": "Good luck!"
      },
      "failed_html": "Unfortunately, you tried twice but did not pass this knowledge check.<br> You have no attempts left.<br> Please check in with your team manager.",
      "button": {
        "close": "Close knowledge check"
      },
      "bonus_html": "You've correctly answered more than <b>%{percentage}%</b> of the questions. You received <b>%{bonus_points}</b> bonus points!"
    },
    "report": "See results",
    "intro_text": "Take a look at the questions below to see which ones you have answered incorrectly.",
    "all_answers_correct": "Congratulations! You correctly answered all the questions from this knowledge check.",
    "your_answers": "Your answers",
    "your_answer": "Your answer",
    "correct_answers": "Correct answers",
    "correct_answer": "Correct answer"
  },
  "feedback": {
    "send": "Send feedback",
    "input_placeholder": "Please give us your feedback",
    "feedback_sent": "Thanks for your feedback.",
    "feedback_error": "We couldn't send your feedback, please try again.",
    "screenshot_prompt": "You will be prompted to share your screen. We'll use this to create a screenshot that will be send along with the feedback."
  },
  "single_sign_on": {
    "failure": "Failed to authenticate with Single Sign On"
  }
};
I18n.translations["tr"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "zaten doğrulandı, lütfen oturum açmayı dene",
      "confirmation_period_expired": "%{period} içinde onaylanması gerekmektedir, lütfen yeni bir tane isteyiniz.",
      "expired": "süresi doldu, lütfen yeni bir tane isteyin",
      "not_found": "bulunamadı",
      "not_locked": "kilitlenmemiş",
      "not_saved": "%{count} tane hata %{resource}'ın kaydını engelledi.",
      "content_type_invalid": "geçersiz dosya tipine sahip",
      "file_size_out_of_range": "dosya boyutu %{file_size} gerekli aralık dışında",
      "limit_out_of_range": "toplam miktar aralık dışında",
      "image_metadata_missing": "geçerli bir imaj değil",
      "dimension_min_inclusion": "%{width} x %{height} piksele eşit ya da büyük olmalı",
      "dimension_max_inclusion": "%{width} x %{height} piksele eşit ya da küçük olmalı",
      "dimension_width_inclusion": "en %{min} ve %{max} piksel aralığı dışında",
      "dimension_height_inclusion": "boy %{min} ve %{max} piksel aralığı dışında",
      "dimension_width_greater_than_or_equal_to": "en %{length} piksele eşit ya da büyük olmalı",
      "dimension_height_greater_than_or_equal_to": "boy %{length} piksele eşit ya da büyük olmalı",
      "dimension_width_less_than_or_equal_to": "en %{length} piksele eşit ya da küçük olmalı",
      "dimension_height_less_than_or_equal_to": "boy %{length} piksele eşit ya da küçük olmalı",
      "dimension_width_equal_to": "en %{length} piksele eşit olmalı",
      "dimension_height_equal_to": "boy %{length} piksele eşit olmalı",
      "aspect_ratio_not_square": "kare bir imaj olmalı",
      "aspect_ratio_not_portrait": "dikey bir imaj olmalı",
      "aspect_ratio_not_landscape": "yatay bir imaj olmalı",
      "aspect_ratio_is_not": "%{aspect_ratio} en boy oranına sahip olmalı",
      "aspect_ratio_unknown": "bilinmeyen en boy oranı"
    },
    "reset_password_token_invalid": "Şifre kodunu sıfırla geçersiz"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "Onaylama gönderildi",
        "confirmation_token": "Onaylama jetonu",
        "confirmed_at": "Onaylandı",
        "created_at": "Oluşturuldu",
        "current_password": "Geçerli Parola",
        "current_sign_in_at": "Mevcut giriş",
        "current_sign_in_ip": "Mevcut giriş IP",
        "email": "E-Posta",
        "encrypted_password": "Şifrelenmiş parola",
        "failed_attempts": "Başarısız girişler",
        "last_sign_in_at": "Son giriş",
        "last_sign_in_ip": "Son giriş IP",
        "locked_at": "Kilitlendi",
        "password": "Parola",
        "password_confirmation": "Parola Doğrulama",
        "remember_created_at": "Oluşturulma tarihini hatırla",
        "remember_me": "Beni Hatırla",
        "reset_password_sent_at": "Parola sıfırlaması gönderildi",
        "reset_password_token": "Parola sıfırlama jetonu",
        "sign_in_count": "Giriş sayısı",
        "unconfirmed_email": "Onaylanmayan e-posta",
        "unlock_token": "Jetonun kilidini kaldır",
        "updated_at": "Güncellendi",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": "Kullanıcı",
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "Pano"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "Yönetici"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "Yalnızca yönetici görüntüleyebilir",
    "visible_for_admin_text": "Bu konu henüz yayınlanmadı ve yalnızca yöneticiler önizleme yapabilir"
  },
  "essential_card": {
    "resume": "Devam",
    "start": "Başla",
    "completed": "Tamamlandı"
  },
  "global": {
    "back": "Geri",
    "exit": "Çıkış",
    "min": "Dk",
    "favorite": "Sık Kullanılanlara Ekle",
    "remove_favorite": "Sık kullanılanlardan kaldır",
    "next": "Sonraki",
    "check": "Kontrol et",
    "save": "Kaydet",
    "assign_content": "İçerik atama",
    "assign_tags": "Etiket atama",
    "sign_off_training": "Eğitimi kapat",
    "sign_off_list_title": "Sınıf ve Görev Temelleri",
    "search_content": "İçerik ara",
    "search_tags": "Etiketleri ara",
    "search_training": "Arama eğitimi",
    "already_completed": "%{item} zaten tamamlandı!"
  },
  "unlock_by_completing": "Tamamlayarak kilidini aç",
  "layout": {
    "header": {
      "navigation": {
        "home": "Ev",
        "profile": "Profil",
        "favorites": "Sık kullanılanlar",
        "learn_more": "Daha fazla bilgi edin",
        "management_report": "Yönetim raporu"
      }
    },
    "footer": {
      "feedback": "Geribildirim"
    }
  },
  "impersonate": {
    "logged_in_as": "olarak giriş yaptı: %{name}",
    "sign_out": "oturumu Kapat"
  },
  "perform": {
    "title": "Gelişimin",
    "points": "Puan",
    "position": "Pozisyonun",
    "achievements": "Başarıların",
    "give_retries": "Yeniden deneme yapın",
    "give_retry": {
      "button": "Yeniden deneme verin",
      "search": "Öğrenme yollarını arayın",
      "subtitle": "Bu kullanıcının üzerinde yeniden denemesi olmayan öğrenme yollarını arayın"
    },
    "manager": {
      "subtitle": "Yönetim raporu",
      "title": "Performans",
      "averages": "Ortalamalar",
      "position": "Konum",
      "achievements": "Başarılar",
      "progress": "Gelişme",
      "chart": {
        "progress": "Öğrenme Yolculuğu Gelişme",
        "knowledge_checks": "Bilgi kontrol puanları",
        "experience": "XP puanları"
      },
      "report": {
        "title": "Raporu indir",
        "button": "CSV'ye aktar"
      },
      "table": {
        "employees": "Çalışanlar",
        "points": "XP puanları",
        "progress": "Gelişme",
        "knowledge_checks": "Bilgi kontrolü ortalama",
        "badges": "Rozetler",
        "groupless_users": "Bir grupta değil (%{count})",
        "grant_retry": "Yeniden deneme verin",
        "granted_retry": "%{Name} 'e '%{learning_path_name}' bilgi kontrolü için yeniden deneme verdin",
        "granted_retry_error": "Yeniden deneme vermek mümkün değildir"
      }
    }
  },
  "learn_more": {
    "title": "Daha fazla bilgi edin",
    "subtitle": "Temel öğrenimler",
    "no_items": "Arama kriterlerinde sonuç bulunamadı",
    "all": "Tüm",
    "search": "Ara",
    "filters": {
      "assigned": "Sana atanmış",
      "mandatory": "Zorunlu",
      "tags": "Etiketlerine göre"
    }
  },
  "bookmark": {
    "title": "Sık kullanılanlar",
    "subtitle": "Kaydettiğin öğeler",
    "load_more": "Daha fazla yükle",
    "no_bookmarks_html": "Şu anda sık kullanılanlar listen boş. Bir makaleyi listene eklemek ve şahsi kütüphaneni oluşturmak için %{icon} seçeneğini tıkla."
  },
  "homepage": {
    "header": {
      "your_performance": "Performansın",
      "no_assigned": "Atanmış içerik yok",
      "no_assigned_explanation": "Şu anda tamamlamadığın atanmış bir içeriğin yok, tüm içeriği keşfetmek için daha fazla bilgi sayfasına git."
    },
    "assigned": {
      "title": "Sana atanmış",
      "button": "Daha fazla bilgi edin"
    },
    "tag": {
      "button": "Görüntüle"
    },
    "favorites": {
      "title": "Favorilerim",
      "button": "Hepsini görüntüle"
    }
  },
  "essential": {
    "no_unlockers_html": "Bu temel öğrenimin kilidini açmak için şu anda geçerli bir öğe yok. Yardım için lütfen site yöneticisiyle iletişime geç",
    "intro": {
      "start": "Öğrenmeye başla",
      "no_content": "Bu temelin hiçbir bölüm öğesi yoktur ve bu nedenle başlatılamaz."
    },
    "general": {
      "correct": "Doğru",
      "incorrect": "Yanlış"
    },
    "conclusion": {
      "end": "Ulaştın",
      "exit": "Sona"
    },
    "class_room": {
      "title": "Sınıf eğitimi",
      "reset": "Eğitimi Sıfırla"
    },
    "assignment": {
      "title": "Görev"
    },
    "self_study": {
      "title": "Bireysel Çalışma"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "Öğrenme yolculuğu"
    },
    "learningpaths": "Öğrenme yolları",
    "title": "Öğrenme yolculuğu",
    "no_unlockers_html": "Bu öğrenme yolculuğunun kilidini açmak için şu anda geçerli bir öğe yok. Yardım için lütfen site yöneticisiyle iletişime geç",
    "knowledge_check": "Bilgi kontrolü",
    "essentials": "Temel Öğrenimler",
    "essential_add": "Seçtiğin temel öğrenim grubuna eklemek yapmak için buradan temel öğrenmimleri aratabilirsin.",
    "essentials_message": "Temel öğrenimlerin kilidini açmak için bilgi yarışmasını tamamlayın",
    "tags": "Etiketler",
    "see_results": "Sonuçları gör",
    "results": {
      "averages": "Ortalamalar",
      "learning_path_progress": "Öğrenme yolu ilerlemesi",
      "knowledge_check_scores": "Bilgi kontrolü puanları",
      "knowledge_check_results": "Bilgi kontrolü sonuçları",
      "xp_points": "Xp noktaları",
      "details": "Detaylar",
      "detailed": {
        "question": "Soru",
        "correct_answer": "Doğru cevap",
        "correct_answers": "Doğru cevap",
        "correct_answers_amount": "Doğru cevap miktarı",
        "incorrect_answers": "Yanlış cevaplar",
        "incorrect_answers_amount": "Yanlış cevap miktarı",
        "no_results": "Sonuç yok",
        "current_questions": "Güncel sorular",
        "historical_questions": "Tarihsel sorular"
      },
      "groups": {
        "name": "Grup ismi",
        "learning_path_progress": "Öğrenme yolu ilerlemesi",
        "first_attempt": "İlk girişim",
        "last_attempt": "Son deneme",
        "achieved_learning_path": "Ulaşılan öğrenme yolu",
        "not_applicable": "Yok",
        "groupless": "Grupsuz"
      }
    }
  },
  "badge": {
    "title": "Başarı kilidi açıldı!",
    "points": "+%{score} puan"
  },
  "download_list": {
    "download_specifications": "Teknik özellikleri indirin",
    "download": "İndir"
  },
  "challenge": {
    "intro": {
      "title_html": "Yarışma",
      "test": "Yarışmada test et!",
      "explanation_html": "Hazır mısın? Bu sorulara olabildiğince iyi cevap vermeye çalış. Tek bir şansın var. Ne kadar çok soruyu doğru yanıtlarsan, o kadar çok temel soru tamamlanmış olarak işaretlenir. <br>Ama daha fazlası var... <br>Her doğru cevap için 100 XP puanı kazanacaksın. Profil sayfana göz at ve iş arkadaşlarına kıyasla kaç puanın olduğunu gör!",
      "ready_set_go": "Hazır... Başla!",
      "already_completed": "Temel öğrenimleri incelemeye devam et",
      "retries": {
        "zero": "Hiç denemeniz yok",
        "one": "Bir tane denemen var!",
        "other": "%{count} deneme hakkınız var!"
      }
    },
    "button": {
      "continue": "Yarışmaya devam et",
      "start": "Yarışmaya başla"
    },
    "circle-chart": {
      "questions": "Sorular"
    },
    "completed": {
      "nice_try": "Güzel!",
      "good_job": "Harika!",
      "failed_html": "Hala öğrenmen gereken şeyler var, bu yüzden tamamlanan temel öğrenimin yok. <br>Aşağıdaki temel öğrenimler keşfet ve incele:",
      "perfect_score": "Aşağıdaki konularda mükemmel bir puan elde ettin:",
      "marked_complete": "Bu konular tamamlandı olarak işaretlenmiş ama onları tekrar inceleyebilirsin",
      "answered_correctly_html": "<b>%{total}</b> sorudan <b>%{correct}</b> soruya doğru yanıt verdin. ",
      "circle-chart": {
        "your-score": "Puanın"
      },
      "button": {
        "close": "Yarışmayı kapat",
        "try-again": "Tekrar deneyin"
      }
    },
    "question": {
      "helper_multiple_choice": "Doğru yanıtı seç",
      "helper_multiple_response": "Birden çok yanıt mümkün",
      "question": "Soru"
    },
    "points": "XP puanları"
  },
  "knowledge_check": {
    "attempts": {
      "first": "ilk girişim",
      "many": "girişim %{count}",
      "previous": "önceki girişim",
      "last": "son deneme"
    },
    "intro": {
      "title_html": "Bilgi kontrolü",
      "test": "Bilgi kontrolünde bilgini test et!",
      "explanation_html": "Çok şey öğrendin, bakalım ne kadarını hatırlıyorsun. Bu bilgi testi öğrenme yolculuğundaki bilgini ölçer.<br><br>Soruların %80'ini doğru yanıtlarsan, öğrenme yolculuğun tamamlanmış olarak işaretlenir ve bir ödül alırsınız.<br>Unutma... her doğru cevap için 100 XP puanı kazanıyorsun.",
      "already_completed": "Bir sonraki öğrenme yolculuğuna göz at!"
    },
    "button": {
      "continue": "Bilgi kontrolüne devam et",
      "start": "Bilgi kontrolüne başla"
    },
    "completed": {
      "good_job": "Aferin!",
      "nice_try": "Maalesef...",
      "passed_check_html": "Tebrikler! Bu bilgi yarışmasını <b>%{percentage}</b> puan ile geçtin.<br>Bir sonraki öğrenme yolculuğuna göz at!",
      "try_again": {
        "title": "Tekrar deneyin",
        "text_html": "Maalesef, <b>%{percentage}%</b> puan aldınız ve bu bilgi kontrolünü geçmediniz. <br> Yalnızca bir deneme kaldı; akıllıca kullanın...",
        "review": "Lütfen bilgi kontrolünü yeniden yapmadan önce öğrenme yolculuğundaki tüm temel öğrenimleri gözden geçirin.",
        "good_luck": "İyi şanslar!"
      },
      "failed_html": "Ne yazık ki iki kez denedin ama bu bilgi testini geçemedin. <br>Hiç denemen kalmadı. <br>Lütfen ekip yöneticine danış.",
      "button": {
        "close": "Bilgi yarışmasını kapat"
      },
      "bonus_html": "Soruların <b>'den çoğunu doğru yanıtladın %{percentage}%</b>. <b>%{bonus_points}</b> bonus puanı kazandın!"
    },
    "report": "Sonuçları gör",
    "intro_text": "Hangilerini yanlış cevapladığınızı görmek için aşağıdaki sorulara bir göz atın.",
    "all_answers_correct": "Tebrikler! Bu bilgi kontrolündeki tüm soruları doğru yanıtladınız.",
    "your_answers": "Cevapların",
    "your_answer": "Senin cevabın",
    "correct_answers": "Doğru cevap",
    "correct_answer": "Doğru cevap"
  },
  "feedback": {
    "send": "Geribildirim gönder",
    "input_placeholder": "Lütfen bize geri bildirimde bulun",
    "feedback_sent": "Geri bildirimin için teşekkür ederiz.",
    "feedback_error": "Geri bildirimini gönderemedik, lütfen tekrar dene.",
    "screenshot_prompt": "Ekranınızı paylaşmanız istenecek, bunu geri bildirimle birlikte gönderilecek bir ekran görüntüsü oluşturmak için kullanacağız."
  },
  "single_sign_on": {
    "failure": "Tek Tuşla Açma ile kimlik doğrulaması başarısız oldu"
  }
};
I18n.translations["es"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "ya fue confirmado, por favor intenta iniciar sesión",
      "confirmation_period_expired": "necesita confirmarse dentro de %{period}, por favor solicita una nueva",
      "expired": "ha expirado, por favor solicita una nueva",
      "not_found": "no se ha encontrado",
      "not_locked": "no estaba bloqueada",
      "not_saved": {
        "one": "Ocurrió un error al tratar de guardar %{resource}:",
        "other": "Ocurrieron %{count} errores al tratar de guardar %{resource}:"
      },
      "content_type_invalid": "tiene un tipo de contenido inválido",
      "file_size_out_of_range": "tamaño %{file_size} no está entre el rango requerido",
      "limit_out_of_range": "el número total está fuera de rango",
      "image_metadata_missing": "no es una imagen válida",
      "dimension_min_inclusion": "debe ser mayor o igual a %{width} x %{height} pixel",
      "dimension_max_inclusion": "debe ser menor o igual a %{width} x %{height} pixel",
      "dimension_width_inclusion": "el ancho no se incluye entre %{min} y %{max} pixel",
      "dimension_height_inclusion": "la altura no se incluye entre %{min} y %{max} pixel",
      "dimension_width_greater_than_or_equal_to": "el ancho debe ser mayor o igual a %{length} pixel",
      "dimension_height_greater_than_or_equal_to": "la altura debe ser mayor o igual a %{length} pixel",
      "dimension_width_less_than_or_equal_to": "el ancho debe ser menor o igual a %{length} pixel",
      "dimension_height_less_than_or_equal_to": "la altura debe ser menor o igual a %{length} pixel",
      "dimension_width_equal_to": "el ancho debe ser igual a %{length} pixel",
      "dimension_height_equal_to": "la altura debe ser igual a %{length} pixel",
      "aspect_ratio_not_square": "debe ser una imagen cuadrada",
      "aspect_ratio_not_portrait": "debe ser una imagen vertical",
      "aspect_ratio_not_landscape": "debe ser una imagen apaisada",
      "aspect_ratio_is_not": "debe tener una relación de aspecto de %{aspect_ratio}",
      "aspect_ratio_unknown": "tiene una relación de aspecto desconocida"
    },
    "reset_password_token_invalid": "Restablecer token de contraseña no es válido"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "Confirmación enviada a",
        "confirmation_token": "Código de confirmación",
        "confirmed_at": "Confirmado en",
        "created_at": "Creado en",
        "current_password": "Contraseña actual",
        "current_sign_in_at": "Fecha del ingreso actual",
        "current_sign_in_ip": "IP del ingreso actual",
        "email": "Email",
        "encrypted_password": "Contraseña cifrada",
        "failed_attempts": "Intentos fallidos",
        "last_sign_in_at": "Fecha del último ingreso",
        "last_sign_in_ip": "IP del último inicio",
        "locked_at": "Fecha de bloqueo",
        "password": "Contraseña",
        "password_confirmation": "Confirmación de la contraseña",
        "remember_created_at": "Fecha de 'Recordarme'",
        "remember_me": "Recordarme",
        "reset_password_sent_at": "Fecha de envío de código para contraseña",
        "reset_password_token": "Código para restablecer contraseña",
        "sign_in_count": "Cantidad de ingresos",
        "unconfirmed_email": "Email no confirmado",
        "unlock_token": "Código de desbloqueo",
        "updated_at": "Actualizado en",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": {
        "one": "Usuario",
        "other": "Usuarios"
      },
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "Tablero"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "Admin"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "Solo visible para el administrador",
    "visible_for_admin_text": "Este elemento aún no se ha publicado y solo los administradores pueden obtener una vista previa de este elemento"
  },
  "essential_card": {
    "resume": "Reanudar",
    "start": "Empezar",
    "completed": "Completado"
  },
  "global": {
    "back": "Atrás",
    "exit": "Salir",
    "min": "Min",
    "favorite": "Agregar a Favoritos",
    "remove_favorite": "Eliminar de favoritos",
    "next": "próximo",
    "check": "Comprueba",
    "save": "Guardar",
    "assign_content": "Asignar contenido",
    "assign_tags": "Asignar etiquetas",
    "sign_off_training": "Terminar sesión de entrenamiento",
    "sign_off_list_title": "Salón de clases y asignación de Esenciales",
    "search_content": "Buscar contenido",
    "search_tags": "Etiquetas de búsqueda",
    "search_training": "Buscar entrenamiento",
    "already_completed": "%{item} ya completado!"
  },
  "unlock_by_completing": "Desbloquea al completar",
  "layout": {
    "header": {
      "navigation": {
        "home": "Casa",
        "profile": "Perfil",
        "favorites": "Favoritos",
        "learn_more": "Aprende más",
        "management_report": "Informe de gestión"
      }
    },
    "footer": {
      "feedback": "Comentarios"
    }
  },
  "impersonate": {
    "logged_in_as": "Conectado como: %{name}",
    "sign_out": "desconectar"
  },
  "perform": {
    "title": "Tu progreso",
    "points": "Puntos",
    "position": "Tu posición",
    "achievements": "Tus logros",
    "give_retries": "Dar reintentos",
    "give_retry": {
      "button": "Dar reintento",
      "search": "Buscar rutas de aprendizaje",
      "subtitle": "Busque rutas de aprendizaje en las que este usuario no haya reintentado"
    },
    "manager": {
      "subtitle": "Informe de gestión",
      "title": "Desempeño",
      "averages": "Promedios",
      "position": "Posición",
      "achievements": "Logros",
      "progress": "Progreso",
      "chart": {
        "progress": "Progreso de las Rutas de Aprendizaje",
        "knowledge_checks": "Calificaciones de tus Repasos de conocimiento",
        "experience": "Puntos de experiencia"
      },
      "report": {
        "title": "Descargar reporte",
        "button": "Exportar a csv"
      },
      "table": {
        "employees": "Empleados",
        "points": "Puntos de experiencia",
        "progress": "Progreso",
        "knowledge_checks": "Calificación promedio de tus Repasos de conocimientos",
        "badges": "Insignias",
        "groupless_users": "No en un grupo (%{count})",
        "grant_retry": "Dar reintento",
        "granted_retry": "Le diste a %{name} un reintento para la verificación de conocimientos de '%{learning_path_name}'",
        "granted_retry_error": "No es posible dar reintentos"
      }
    }
  },
  "learn_more": {
    "title": "Aprende más",
    "subtitle": "Los Esenciales",
    "no_items": "No hay elementos disponibles dentro de los criterios de búsqueda",
    "all": "Todo",
    "search": "Buscar",
    "filters": {
      "assigned": "Te ha sido asignado",
      "mandatory": "Obligatorio",
      "tags": "Basado en tus etiquetas"
    }
  },
  "bookmark": {
    "title": "Favoritos",
    "subtitle": "Tus elementos guardados",
    "load_more": "Cargar más",
    "no_bookmarks_html": "Actualmente no tienes favoritos, haz clic en el %{icon} para marcar como favorito algún artículo de tu interes y empieza a crear tu biblioteca personal."
  },
  "homepage": {
    "header": {
      "your_performance": "Tu desempeño",
      "no_assigned": "Sin contenido asignado",
      "no_assigned_explanation": "Actualmente has revisado y completado todos los contenidos asignados. Te invitamos visitar la página de \"Aprende más\" para explorar todo el contenido que tenemos disponible."
    },
    "assigned": {
      "title": "Te ha sido asignado",
      "button": "Aprende más"
    },
    "tag": {
      "button": "Vista"
    },
    "favorites": {
      "title": "Mis favoritos",
      "button": "Ver todos"
    }
  },
  "essential": {
    "no_unlockers_html": "Actualmente no hay elementos disponibles que te permitan desbloquear este Esencial. Contacta al administrador de sitio para obtener ayuda",
    "intro": {
      "start": "Empezar a aprender",
      "no_content": "Este elemento esencial no tiene elementos de capítulo y, por lo tanto, no se puede iniciar."
    },
    "general": {
      "correct": "Correcto",
      "incorrect": "Incorrecta"
    },
    "conclusion": {
      "end": "Has llegado",
      "exit": "Al final"
    },
    "class_room": {
      "title": "Formación en el aula",
      "reset": "Restablecer entrenamiento"
    },
    "assignment": {
      "title": "Asignación"
    },
    "self_study": {
      "title": "Autoestudio"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "Ruta de aprendizaje"
    },
    "learningpaths": "Rutas de aprendizaje",
    "title": "Ruta de aprendizaje",
    "no_unlockers_html": "Actualmente no hay elementos disponibles que te permitan desbloquear esta ruta de aprendizaje. Contacta al administrador del sitio para obtener ayuda",
    "knowledge_check": "Repaso de conocimientos",
    "essentials": "Esenciales",
    "essential_add": "Puedes buscar aquí los Esenciales para agregar a su grupo de Esenciales seleccionado.",
    "essentials_message": "Completa el Desafío para porder desbloquear el Esencial",
    "tags": "Etiquetas",
    "see_results": "Ver resultados",
    "results": {
      "averages": "Promedios",
      "learning_path_progress": "Progreso de la ruta de aprendizaje",
      "knowledge_check_scores": "Puntuaciones de comprobación de conocimientos",
      "knowledge_check_results": "Resultados de la verificación de conocimientos",
      "xp_points": "Puntos xp",
      "details": "Detalles",
      "detailed": {
        "question": "Pregunta",
        "correct_answer": "Respuesta correcta",
        "correct_answers": "Respuestas correctas",
        "correct_answers_amount": "Cantidad de respuestas correctas",
        "incorrect_answers": "Respuestas incorrectas",
        "incorrect_answers_amount": "Cantidad de respuestas incorrectas",
        "no_results": "No hay resultados",
        "current_questions": "Preguntas actuales",
        "historical_questions": "Preguntas históricas"
      },
      "groups": {
        "name": "Nombre del grupo",
        "learning_path_progress": "Progreso de la ruta de aprendizaje",
        "first_attempt": "Primer intento",
        "last_attempt": "Último intento",
        "achieved_learning_path": "Ruta de aprendizaje lograda",
        "not_applicable": "N/A",
        "groupless": "Sin grupo"
      }
    }
  },
  "badge": {
    "title": "¡Nuevo logro desbloqueado!",
    "points": "+%{score} puntos"
  },
  "download_list": {
    "download_specifications": "Descargar las especificaciones",
    "download": "Descargar"
  },
  "challenge": {
    "intro": {
      "title_html": "Desafío",
      "test": "¡Pruébalo en el Desafío!",
      "explanation_html": "¿Estás listo? Trata de responder las siguientes preguntas tan bien como pueda. Sólo tienes una oportunidad. Mientras más preguntas respondas correctamente, más Esenciales se marcarán como completados.<br>Pero... ¡aún hay más!<br>Por cada respuesta correcta recibe 100 puntos de experiencia, revisa tu Perfil y ve tu posición con respecto a tus compañeros.",
      "ready_set_go": "¡En sus marcas ... listos ... fuera!",
      "already_completed": "Continuar estudiando los Esenciales",
      "retries": {
        "zero": "¡No te quedan intentos!",
        "one": "¡Te queda un intento!",
        "other": "Te quedan %{count} intentos!"
      }
    },
    "button": {
      "continue": "Continuar el desafío",
      "start": "Empezar desafío"
    },
    "circle-chart": {
      "questions": "Preguntas"
    },
    "completed": {
      "nice_try": "¡Buen intento!",
      "good_job": "¡Buen trabajo!",
      "failed_html": "Todavía tienes algo que aprender, así que el Esencial no ha sido completado.<br> Te recomendamos explorar y estudiar lo siguiente:",
      "perfect_score": "Ha logrado una puntuación perfecta en los siguientes temas:",
      "marked_complete": "Estos temas han sido completados, pero si deseas volver a estudiarlos ¡adelante!",
      "answered_correctly_html": "Ha respondido <b>%{correct}</b> de <b>%{total}</b> respuestas correctamente.",
      "circle-chart": {
        "your-score": "Tu puntuación"
      },
      "button": {
        "close": "Terminar desafío",
        "try-again": "Inténtalo de nuevo"
      }
    },
    "question": {
      "helper_multiple_choice": "Elige la respuesta correcta",
      "helper_multiple_response": "Múltiples respuestas posibles",
      "question": "Pregunta"
    },
    "points": "Puntos de experiencia"
  },
  "knowledge_check": {
    "attempts": {
      "first": "primer intento",
      "many": "intento %{count}",
      "previous": "intento anterior",
      "last": "último intento"
    },
    "intro": {
      "title_html": "Repaso de conocimientos",
      "test": "¡Pon a prueba lo aprendido en el Repaso de conocimientos!",
      "explanation_html": "Has aprendido mucho, es momento de desmostrar lo que sabes. Este Repaso de conocimientos medirá lo aprendido durante esta ruta de aprendizaje.<br><br>Si respondes correctamente el 80% de las preguntas, la Ruta de aprendizaje se marcará como completa y recibirás un trofeo.<br>No lo olvides que obtienes 100 puntos de experiencia por cada respuesta correcta.",
      "already_completed": "¡Echa un vistazo a la siguiente Ruta de aprendizaje!"
    },
    "button": {
      "continue": "Continuar con la revisión de conocimientos",
      "start": "Iniciar el Repaso de conocimientos"
    },
    "completed": {
      "good_job": "¡Buen trabajo!",
      "nice_try": "Muy mal...",
      "passed_check_html": "¡Genial! Has aprobado esta comprobación de conocimientos con una calificación de <b>%{percentage}%</b>.<br>¡Ahora revisa la siguiente ruta de aprendizaje!",
      "try_again": {
        "title": "Inténtalo de nuevo",
        "text_html": "Lamentablemente, obtuvo un <b>%{percentage}%</b> y no pasó esta verificación de conocimientos.<br>Solo queda un intento; úsalo con sabiduría...",
        "review": "Revisa todos los Esenciales en tu Ruta de aprendizaje antes de volver a intentar tu Repaso de conocimientos.",
        "good_luck": "¡Buena suerte!"
      },
      "failed_html": "Desafortunadamente, ya has intentado dos veces, pero no pasaste este Repaso de conocimientos.<br>No te quedan más intentos.<br>Por favor, consulta con tu Entrenador",
      "button": {
        "close": "Terminar Repaso de Conocimientos"
      },
      "bonus_html": "Has respondido correctamente a más de <b>%{percentage}%</b> de las preguntas. ¡Has recibido puntos de bonificación de <b>%{bonus_points}</b>!"
    },
    "report": "Ver resultados",
    "intro_text": "eche un vistazo a las preguntas a continuación para ver cuáles ha respondido incorrectamente.",
    "all_answers_correct": "¡Felicidades! Respondió correctamente a todas las preguntas de esta verificación de conocimientos.",
    "your_answers": "Tus respuestas",
    "your_answer": "Tu respuesta",
    "correct_answers": "Respuestas correctas",
    "correct_answer": "Respuesta correcta"
  },
  "feedback": {
    "send": "Enviar comentarios",
    "input_placeholder": "Por favor, compartenos tus comentarios",
    "feedback_sent": "Gracias por tus comentarios.",
    "feedback_error": "No pudimos enviar tus comentarios, por favor inténtalo de nuevo.",
    "screenshot_prompt": "Se le pedirá que comparta su pantalla, lo usaremos para crear una captura de pantalla que se enviará junto con los comentarios."
  },
  "single_sign_on": {
    "failure": "No es oosible autenticar con el Inicio de Sesión Único"
  }
};
I18n.translations["ru"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "уже было подтверждено, пожалуйста, попробуйте войти заново",
      "confirmation_period_expired": "должен быть подтвержден в течение %{period}, пожалуйста, повторите запрос на подтверждение",
      "expired": "устарела. Пожалуйста, запросите новую",
      "not_found": "не найден",
      "not_locked": "не заблокирован",
      "not_saved": {
        "few": "%{resource}: сохранение не удалось из-за %{count} ошибок:",
        "many": "%{resource}: сохранение не удалось из-за %{count} ошибок:",
        "one": "%{resource}: сохранение не удалось из-за %{count} ошибки:",
        "other": "%{resource}: сохранение не удалось из-за %{count} ошибки:"
      },
      "content_type_invalid": "имеет недопустимый тип содержимого",
      "file_size_out_of_range": "размер %{file_size} больше требуемого",
      "limit_out_of_range": "количество файлов больше требуемого",
      "image_metadata_missing": "не является допустимым изображением",
      "dimension_min_inclusion": "должен быть больше или равно %{width} x %{height} пикселям",
      "dimension_max_inclusion": "должно быть меньше или равно %{width} x %{height} пикселям",
      "dimension_width_inclusion": "ширина не включена между %{min} и %{max} пикселям",
      "dimension_height_inclusion": "высота не включена между %{min} и %{max}  пикселям",
      "dimension_width_greater_than_or_equal_to": "ширина должна быть больше или равна %{length} пикселям",
      "dimension_height_greater_than_or_equal_to": "высота должна быть больше или равна %{length} пикселям",
      "dimension_width_less_than_or_equal_to": "ширина должна быть меньше или равна %{length} пикселям",
      "dimension_height_less_than_or_equal_to": "высота должна быть меньше или равна %{length} пикселям",
      "dimension_width_equal_to": "ширина должна быть равна %{length} пикселям",
      "dimension_height_equal_to": "высота должна быть равна %{length} пикселям",
      "aspect_ratio_not_square": "должно быть квадратное изображение",
      "aspect_ratio_not_portrait": "должно быть портретное изображение",
      "aspect_ratio_not_landscape": "должно быть пейзажное изображение",
      "aspect_ratio_is_not": "должен иметь соотношение сторон %{aspect_ratio}",
      "aspect_ratio_unknown": "имеет неизвестное соотношение сторон"
    },
    "reset_password_token_invalid": "Неверный токен пароля"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "Дата отправки подтверждения",
        "confirmation_token": "Токен подтверждения",
        "confirmed_at": "Дата подтверждения",
        "created_at": "Дата создания",
        "current_password": "Текущий пароль",
        "current_sign_in_at": "Дата текущего входа",
        "current_sign_in_ip": "IP текущего входа",
        "email": "Email",
        "encrypted_password": "Зашифрованный пароль",
        "failed_attempts": "Неудачные попытки",
        "last_sign_in_at": "Дата последнего входа",
        "last_sign_in_ip": "IP последнего входа",
        "locked_at": "Дата блокировки",
        "password": "Пароль",
        "password_confirmation": "Подтверждение пароля",
        "remember_created_at": "Дата создания запоминания",
        "remember_me": "Запомнить меня",
        "reset_password_sent_at": "Дата отправки сброса пароля",
        "reset_password_token": "Токен сброса пароля",
        "sign_in_count": "Количество входов",
        "unconfirmed_email": "Неподтвержденный email",
        "unlock_token": "Токен разблокировки",
        "updated_at": "Дата обновления",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": {
        "few": "Пользователя",
        "many": "Пользователей",
        "one": "Пользователь",
        "other": "Пользователи"
      },
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "Панель"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "Администратор"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "Доступен только для админа",
    "visible_for_admin_text": "Этот элемент еще не опубликован, и только администраторы могут его просматривать."
  },
  "essential_card": {
    "resume": "Возобновить",
    "start": "Начать",
    "completed": "Завершен"
  },
  "global": {
    "back": "Назад",
    "exit": "Выход",
    "min": "Минут",
    "favorite": "Добавить в избранное",
    "remove_favorite": "Удалить из избранного",
    "next": "Следующи",
    "check": "Проверьте",
    "save": "Сохранить",
    "assign_content": "Назначить материал/ курс",
    "assign_tags": "Назначить тег",
    "sign_off_training": "Выйти из тренинга",
    "sign_off_list_title": "Курс и основные задания",
    "search_content": "Поиск содержимого",
    "search_tags": "Поиск тега",
    "search_training": "Поиск тренинга",
    "already_completed": "%{item} уже завершен!"
  },
  "unlock_by_completing": "Разблокировать, выполнив",
  "layout": {
    "header": {
      "navigation": {
        "home": "Начальная страница",
        "profile": "Профиль",
        "favorites": "Избранное",
        "learn_more": "Подробнее",
        "management_report": "Отчет об управлении"
      }
    },
    "footer": {
      "feedback": "Обратная связь"
    }
  },
  "impersonate": {
    "logged_in_as": "Вы вошли как: %{name}",
    "sign_out": "выход"
  },
  "perform": {
    "title": "Ваш прогресс",
    "points": "Баллы",
    "position": "Ваша позиция",
    "achievements": "Ваши достижения",
    "give_retries": "Дайте повторные попытки",
    "give_retry": {
      "button": "Повторить попытку",
      "search": "Поиск путей обучения",
      "subtitle": "Поиск путей обучения, по которым у этого пользователя нет повторных попыток"
    },
    "manager": {
      "subtitle": "Отчет об управлении",
      "title": "Производительность",
      "averages": "Средние",
      "position": "Позиция",
      "achievements": "Достижения",
      "progress": "Прогресс",
      "chart": {
        "progress": "Ход обучения",
        "knowledge_checks": "Результат проверки знаний",
        "experience": "Баллы XP"
      },
      "report": {
        "title": "Скачать отчет",
        "button": "Экспорт в csv"
      },
      "table": {
        "employees": "Сотрудники",
        "points": "Баллы XP",
        "progress": "Прогресс",
        "knowledge_checks": "Средний результат по проверке знаний",
        "badges": "Значки",
        "groupless_users": "Не в группе (%{count})",
        "grant_retry": "Повторить попытку",
        "granted_retry": "Вы дали %{name} повторную попытку проверки знаний '%{learning_path_name}'",
        "granted_retry_error": "Повторные попытки невозможны"
      }
    }
  },
  "learn_more": {
    "title": "Подробнее",
    "subtitle": "Основы",
    "no_items": "По указанным критериям результатов не найдено",
    "all": "Все",
    "search": "Поиск",
    "filters": {
      "assigned": "Вам назначено",
      "mandatory": "Обязательно",
      "tags": "На основе ваших тегов"
    }
  },
  "bookmark": {
    "title": "Избранное",
    "subtitle": "Сохраненные элементы",
    "load_more": "Загрузите больше",
    "no_bookmarks_html": "В настоящее время у вас нет избранного, нажмите на %{icon} чтобы добавить статью в избранное и создать собственную личную библиотеку с легкими для поиска статьями."
  },
  "homepage": {
    "header": {
      "your_performance": "Ваша производительность",
      "no_assigned": "Нет назначенного контента",
      "no_assigned_explanation": "В настоящее время у вас нет назначенного контента, который вы еще не завершили, перейдите на страницу Подробнее, чтобы изучить весь контент."
    },
    "assigned": {
      "title": "Вам назначено",
      "button": "Подробнее"
    },
    "tag": {
      "button": "Вид"
    },
    "favorites": {
      "title": "Мои любимые",
      "button": "Посмотреть все"
    }
  },
  "essential": {
    "no_unlockers_html": "В настоящее время у вас нет элементов, которые позволяют разблокировать данное задание. Пожалуйста, обратитесь к администратору вашего сайта за помощью.",
    "intro": {
      "start": "Начать обучение",
      "no_content": "У этого существа нет пунктов главы, поэтому его нельзя запустить."
    },
    "general": {
      "correct": "Правильно",
      "incorrect": "Неправильный"
    },
    "conclusion": {
      "end": "Вы достигли",
      "exit": "цели"
    },
    "class_room": {
      "title": "Обучение в классе",
      "reset": "Сбросить обучение"
    },
    "assignment": {
      "title": "Присвоение"
    },
    "self_study": {
      "title": "Самообучение"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "План обучение"
    },
    "learningpaths": "Пути обучения",
    "title": "План обучение",
    "no_unlockers_html": "В настоящее время у вас нет элементов, которые позволяют разблокировать данный план обучения. Пожалуйста, обратитесь к администратору вашего сайта за помощью.",
    "knowledge_check": "Проверка знаний",
    "essentials": "Основы",
    "essential_add": "Здесь Вы можете искать задания, чтобы добавить в выбранную группу.",
    "essentials_message": "Выполните задачу, чтобы разблокировать задание",
    "tags": "теги",
    "see_results": "Посмотреть результаты",
    "results": {
      "averages": "Средние",
      "learning_path_progress": "Прогресс пути обучения",
      "knowledge_check_scores": "Результаты проверки знаний",
      "knowledge_check_results": "Результаты проверки знаний",
      "xp_points": "Очки опыта",
      "details": "Детали",
      "detailed": {
        "question": "Вопрос",
        "correct_answer": "Правильный ответ",
        "correct_answers": "Правильные ответы",
        "correct_answers_amount": "Количество правильных ответов",
        "incorrect_answers": "Неправильные ответы",
        "incorrect_answers_amount": "Количество неправильных ответов",
        "no_results": "Нет результатов",
        "current_questions": "Текущие вопросы",
        "historical_questions": "Исторические вопросы"
      },
      "groups": {
        "name": "Название группы",
        "learning_path_progress": "Прогресс пути обучения",
        "first_attempt": "Первая попытка",
        "last_attempt": "Последняя попытка",
        "achieved_learning_path": "Достигнутый путь обучения",
        "not_applicable": "Нет данных",
        "groupless": "безгрупповой"
      }
    }
  },
  "badge": {
    "title": "Достижение разблокировано!",
    "points": "+%{score} баллов"
  },
  "download_list": {
    "download_specifications": "Скачать спецификации",
    "download": "Скачать"
  },
  "challenge": {
    "intro": {
      "title_html": "Вызов",
      "test": "Проверьте это в испытании!",
      "explanation_html": "Вы готовы? Попробуйте ответить на эти вопросы настолько хорошо, насколько сможете. У тебя только один шанс. Чем больше вопросов Вы ответите правильно, тем больше раз деловбудет помечено как выполненно. <br>Но это еще не все... <br>Вы получите 100 баллов XP за каждый правильный ответ. Проверьте страницу профиля, чтобы увидеть Ваш уровень по сравнению с вашими коллегами!",
      "ready_set_go": "На старт, внимание, марш!",
      "already_completed": "Продолжайте изучать курс.",
      "retries": {
        "zero": "У вас нет попыток",
        "one": "У вас есть одна попытка!",
        "other": "У вас есть %{count} попыток!"
      }
    },
    "button": {
      "continue": "Продолжить задание",
      "start": "Начать задание"
    },
    "circle-chart": {
      "questions": "Вопросы"
    },
    "completed": {
      "nice_try": "Хорошая попытка!",
      "good_job": "Удачи!",
      "failed_html": "У вас еще есть чему поучиться, поэтому задания не были завершены. <br> Изучите следующие задания:",
      "perfect_score": "Вы достигли идеального балла по следующим темам:",
      "marked_complete": "Эти темы были отмечены как завершенные, но вы все равно можете их изучить.",
      "answered_correctly_html": "Вы ответили на <b>%{correct}</b> из <b>%{total}</b> ответов правильно.",
      "circle-chart": {
        "your-score": "Ваш результат"
      },
      "button": {
        "close": "Ближайшее задание",
        "try-again": "Попробуй еще раз"
      }
    },
    "question": {
      "helper_multiple_choice": "Выберите правильный ответ",
      "helper_multiple_response": "Возможны несколько ответов",
      "question": "вопрос"
    },
    "points": "Баллы XP"
  },
  "knowledge_check": {
    "attempts": {
      "first": "Первая попытка",
      "many": "пытаться %{count}",
      "previous": "предыдущая попытка",
      "last": "последняя попытка"
    },
    "intro": {
      "title_html": "Проверка знаний",
      "test": "Проверьте свои знания в проверке знаний!",
      "explanation_html": "Вы многому научились, давайте посмотрим, что вы знаете. Эта проверка знаний измеряет ваши знания в данном учебном плане.<br><br>Если вы правильно ответите на 80% вопросов, учебный план помечается как выполнен, и вы получите трофей.<br>Не забудьте... Вы получаете 100 очков XP за каждый правильный ответ.",
      "already_completed": "Проверьте следующий план обучения!"
    },
    "button": {
      "continue": "Продолжить проверку знаний",
      "start": "Начать проверку знаний"
    },
    "completed": {
      "good_job": "Отличная работа!",
      "nice_try": "Очень плохо...",
      "passed_check_html": "Прекрасно! Вы прошли эту проверку знаний, набрав <b>%{percentage}%</b>.<br>Проверьте следующий план обучения!",
      "try_again": {
        "title": "Попробуй еще раз",
        "text_html": "К сожалению, вы набрали <b>%{percentage}%</b> и не прошли эту проверку знаний. <br> Осталась только одна попытка; использовать его мудро...",
        "review": "Пожалуйста, просмотрите все задания в плане обучения, прежде чем повторно проходить проверку знаний.",
        "good_luck": "Удачи!"
      },
      "failed_html": "К сожалению, вы пытались дважды, но не прошли эту проверку знаний.<br>У вас не осталось попыток.<br>Пожалуйста, свяжитесь с менеджером вашей команды.",
      "button": {
        "close": "Тщательная проверка знаний"
      },
      "bonus_html": "Вы правильно ответили на более чем <b>%{percentage}%</b> вопросов. Вы получили <b>%{bonus_points}</b> бонусных баллов!"
    },
    "report": "Посмотреть результаты",
    "intro_text": "взгляните на приведенные ниже вопросы, чтобы узнать, на какие из них вы ответили неверно.",
    "all_answers_correct": "Поздравляю! Вы правильно ответили на все вопросы этой проверки знаний.",
    "your_answers": "Ваши ответы",
    "your_answer": "Ваш ответ",
    "correct_answers": "Правильные ответы",
    "correct_answer": "Правильный ответ"
  },
  "feedback": {
    "send": "Отправить отзыв",
    "input_placeholder": "Пожалуйста, оставьте отзыв",
    "feedback_sent": "Спасибо за ваш отзыв.",
    "feedback_error": "Мы не смогли отправить ваш отзыв, пожалуйста, попробуйте еще раз.",
    "screenshot_prompt": "Вам будет предложено поделиться своим экраном, мы будем использовать его для создания снимка экрана, который будет отправлен вместе с отзывом."
  },
  "single_sign_on": {
    "failure": "Не удалось подтвердить подлинность с помощью единого входа"
  }
};
I18n.translations["pt"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "já estava confirmado, por favor, tente assinar em",
      "confirmation_period_expired": "necessitava de confirmação até %{period}, por favor faça um novo pedido",
      "expired": "expirou, por favor solicite um novo",
      "not_found": "não foi encontrado",
      "not_locked": "não foi bloqueado",
      "not_saved": {
        "one": "%{count} erro impediu %{resource} de ser gravado:",
        "other": "%{count} erros impediram %{resource} de ser gravado:"
      },
      "content_type_invalid": "has an invalid content type",
      "file_size_out_of_range": "size %{file_size} is not between required range",
      "limit_out_of_range": "total number is out of range",
      "image_metadata_missing": "is not a valid image",
      "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
      "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
      "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
      "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
      "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
      "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
      "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
      "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
      "dimension_width_equal_to": "width must be equal to %{length} pixel",
      "dimension_height_equal_to": "height must be equal to %{length} pixel",
      "aspect_ratio_not_square": "must be a square image",
      "aspect_ratio_not_portrait": "must be a portrait image",
      "aspect_ratio_not_landscape": "must be a landscape image",
      "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
      "aspect_ratio_unknown": "has an unknown aspect ratio"
    },
    "reset_password_token_invalid": "Redefinir token de senha inválido"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "Confirmação enviada em",
        "confirmation_token": "Código de confirmação",
        "confirmed_at": "Confirmado em",
        "created_at": "Criado em",
        "current_password": "Palavra-passe atual",
        "current_sign_in_at": "Entrada atual em",
        "current_sign_in_ip": "Entrada atual com IP",
        "email": "Email",
        "encrypted_password": "Palavra-passe cifrada",
        "failed_attempts": "Tentativas falhadas",
        "last_sign_in_at": "Última entrada em",
        "last_sign_in_ip": "Última entrada com IP",
        "locked_at": "Bloqueado em",
        "password": "Palavra-passe",
        "password_confirmation": "Confirmar palavra-passe",
        "remember_created_at": "Lembrança criada em",
        "remember_me": "Lembrar-se de mim",
        "reset_password_sent_at": "Recuperação de palavra-passe enviada em",
        "reset_password_token": "Código para recuperar palavra-passe",
        "sign_in_count": "Número de entradas",
        "unconfirmed_email": "Email não confirmado",
        "unlock_token": "Código de desbloqueio",
        "updated_at": "Atualizado em",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": {
        "one": "Utilizador",
        "other": "Utilizadores"
      },
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "Painel"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "Admin"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "Apenas visível para admin",
    "visible_for_admin_text": "Este item ainda não foi publicado e apenas os admins podem visualizar"
  },
  "essential_card": {
    "resume": "Resumo",
    "start": "Começar",
    "completed": "Concluído"
  },
  "global": {
    "back": "Voltar",
    "exit": "Saída",
    "min": "Min",
    "favorite": "Adicionar a Favoritos",
    "remove_favorite": "Remover dos favoritos",
    "next": "Próximo",
    "check": "Verificar",
    "save": "Salvar",
    "assign_content": "Atribuir conteúdo",
    "assign_tags": "Atribuir tags",
    "sign_off_training": "Fianlizar o treinamento",
    "sign_off_list_title": "Sala de aula e Itens Tarefas Essenciais",
    "search_content": "Conteúdo de pesquisa",
    "search_tags": "Tags de pesquisa",
    "search_training": "Treinamento de pesquisa",
    "already_completed": "%{item} já foi concluído!"
  },
  "unlock_by_completing": "Desbloqueie completando",
  "layout": {
    "header": {
      "navigation": {
        "home": "Página principal",
        "profile": "Perfil",
        "favorites": "Favoritos",
        "learn_more": "Saiba Mais",
        "management_report": "Relatório de gestão"
      }
    },
    "footer": {
      "feedback": "Feedback"
    }
  },
  "impersonate": {
    "logged_in_as": "logado como: %{name}",
    "sign_out": "Sair"
  },
  "perform": {
    "title": "Seu progresso",
    "points": "Pontos",
    "position": "Sua posição",
    "achievements": "Suas conquistas",
    "give_retries": "Dê novas tentativas",
    "give_retry": {
      "button": "Tentar novamente",
      "search": "Pesquisar caminhos de aprendizagem",
      "subtitle": "Procure por caminhos de aprendizagem que este usuário não tenha nenhuma tentativa"
    },
    "manager": {
      "subtitle": "Relatório de gestão",
      "title": "Desempenho",
      "averages": "Médias",
      "position": "Posição",
      "achievements": "Realização",
      "progress": "Progresso",
      "chart": {
        "progress": "Progresso dos caminhos de aprendizagem",
        "knowledge_checks": "Pontuações de verificação de conhecimento",
        "experience": "Pontos XP"
      },
      "report": {
        "title": "Relatório de download",
        "button": "Exportar em csv"
      },
      "table": {
        "employees": "Funcionários",
        "points": "Pontos XP",
        "progress": "Progresso",
        "knowledge_checks": "Verificações de conhecimento média",
        "badges": "Emblemas",
        "groupless_users": "Não está em um grupo (%{count})",
        "grant_retry": "Spróbuj ponownie",
        "granted_retry": "Dałeś %{name} ponowną próbę sprawdzenia wiedzy '%{learning_path_name}'",
        "granted_retry_error": "Podawanie ponownych prób nie jest możliwe"
      }
    }
  },
  "learn_more": {
    "title": "Saiba Mais",
    "subtitle": "A tarefa essencial",
    "no_items": "Não há tarefas dentro de seus critérios de pesquisa",
    "all": "Todos",
    "search": "Busca",
    "filters": {
      "assigned": "Atribuído a você",
      "mandatory": "Obrigatório",
      "tags": "Com base em suas tags"
    }
  },
  "bookmark": {
    "title": "Favoritos",
    "subtitle": "Seus itens salvos",
    "load_more": "Carregar mais",
    "no_bookmarks_html": "Atualmente, você não tem nenhum favorito, clique no %{icon} para escolher um artigo para criar sua própria biblioteca pessoal com artigos fáceis de encontrar."
  },
  "homepage": {
    "header": {
      "your_performance": "Seu desempenho",
      "no_assigned": "Sem conteúdo atribuído",
      "no_assigned_explanation": "Você ainda não tem nenhum conteúdo atribuído que ainda não tenha concluído, vá até a página \"saiba mais\" para explorar todo o conteúdo."
    },
    "assigned": {
      "title": "Atribuído a você",
      "button": "Saiba Mais"
    },
    "tag": {
      "button": "Ver"
    },
    "favorites": {
      "title": "Meus favoritos",
      "button": "Ver tudo"
    }
  },
  "essential": {
    "no_unlockers_html": "No momento, não há tarefas disponíveis que permitam desbloquear essa tarefa essencial. Entre em contato com o admin para obter assistência",
    "intro": {
      "start": "Comece a aprender",
      "no_content": "Este essencial não tem itens de capítulo e, portanto, não pode ser iniciado."
    },
    "general": {
      "correct": "Correto",
      "incorrect": "Incorreto"
    },
    "conclusion": {
      "end": "Você chegou",
      "exit": "Ao fim"
    },
    "class_room": {
      "title": "Treinamento em sala de aula",
      "reset": "Reiniciar treinamento"
    },
    "assignment": {
      "title": "Tarefa"
    },
    "self_study": {
      "title": "Autoestudo"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "Caminho de aprendizagem"
    },
    "learningpaths": "Caminhos de aprendizagem",
    "title": "Caminho de aprendizagem",
    "no_unlockers_html": "No momento, não há tarefas disponíveis que permitam desbloquear esse caminho de aprendizado. Entre em contato com o admin para obter assistência",
    "knowledge_check": "Verificação de conhecimento",
    "essentials": "Essenciais",
    "essential_add": "Você pode pesquisar aqui o essencial para adicionar ao seu grupo essencial selecionado.",
    "essentials_message": "Complete o desafio para desbloquear a tarefa essencial",
    "tags": "Tag",
    "see_results": "Ver resultados",
    "results": {
      "averages": "Médias",
      "learning_path_progress": "Progresso do caminho de aprendizagem",
      "knowledge_check_scores": "Pontuação de verificação de conhecimento",
      "knowledge_check_results": "Resultados da verificação de conhecimento",
      "xp_points": "Pontos Xp",
      "details": "Detalhes",
      "detailed": {
        "question": "Questão",
        "correct_answer": "Resposta correta",
        "correct_answers": "Respostas corretas",
        "correct_answers_amount": "Quantidade de respostas corretas",
        "incorrect_answers": "Respostas incorretas",
        "incorrect_answers_amount": "Quantidade de respostas incorretas",
        "no_results": "Sem resultados",
        "current_questions": "Questões atuais",
        "historical_questions": "Questões históricas"
      },
      "groups": {
        "name": "Nome do grupo",
        "learning_path_progress": "Progresso do caminho de aprendizagem",
        "first_attempt": "Primeira tentativa",
        "last_attempt": "Última tentativa",
        "achieved_learning_path": "Caminho de aprendizagem alcançado",
        "not_applicable": "N/D",
        "groupless": "Sem grupos"
      }
    }
  },
  "badge": {
    "title": "Conquista desbloqueada!",
    "points": "+%{score} pontos"
  },
  "download_list": {
    "download_specifications": "Faça o download das especificações",
    "download": "Baixar"
  },
  "challenge": {
    "intro": {
      "title_html": "Desafio",
      "test": "Teste no Desafio!",
      "explanation_html": "Está pronto? Tente responder essas perguntas da melhor maneira possível. Você só tem uma chance. Quanto mais perguntas você responder corretamente, mais tarefas essenciais serão marcados como concluídos.<br>Além disso... <br>você receberá 100 pontos XP para cada resposta correta. Confira sua página de perfil e veja sua classificação em comparação a seus colegas!",
      "ready_set_go": "Pronto... pode começar!",
      "already_completed": "Continue estudando a tarefa essencial.",
      "retries": {
        "zero": "Você não tem mais tentativas",
        "one": "Você tem uma tentativa!",
        "other": "Você tem %{count} tentativas!"
      }
    },
    "button": {
      "continue": "Continuar o desafio",
      "start": "Iniciar desafio"
    },
    "circle-chart": {
      "questions": "Perguntas"
    },
    "completed": {
      "nice_try": "Boa tentativa!",
      "good_job": "Bom trabalho!",
      "failed_html": "Você ainda tem algo a aprender, então nenhuma tarefa essencial foi concluída. <br>Explore e estude as seguintes tarefas essenciais:",
      "perfect_score": "Você alcançou uma pontuação perfeita nos seguintes tópicos:",
      "marked_complete": "Esses tópicos foram marcados completos, mas sinta-se livre para estudá-los de qualquer maneira.",
      "answered_correctly_html": "Você respondeu corretamente <b>%{correct}</b> de <b>%{total}</b> respostas.",
      "circle-chart": {
        "your-score": "Sua pontuação"
      },
      "button": {
        "close": "Encerrar desafio",
        "try-again": "Tente novamente"
      }
    },
    "question": {
      "helper_multiple_choice": "Escolha a resposta certa",
      "helper_multiple_response": "Múltipla escolha",
      "question": "Questão"
    },
    "points": "Pontos XP"
  },
  "knowledge_check": {
    "attempts": {
      "first": "primeira tentativa",
      "many": "tentativa %{count}",
      "previous": "tentativa anterior",
      "last": "última tentativa"
    },
    "intro": {
      "title_html": "Verificação de conhecimento",
      "test": "Teste seus conhecimentos na verificação de conhecimento!",
      "explanation_html": "Você aprendeu muito, vamos ver o que você sabe. Essa verificação de conhecimento mede seu conhecimento sobre esse caminho de aprendizagem.<br><br>Se você responder 80% das perguntas corretamente, o caminho de aprendizagem é marcado como completo e você receberá um troféu.<br>Não se esqueça... você recebe 100 pontos XP para cada resposta correta.",
      "already_completed": "Confira o próximo caminho de aprendizado!"
    },
    "button": {
      "continue": "Continue a verificação de conhecimento",
      "start": "Inicie a verificação do conhecimento"
    },
    "completed": {
      "good_job": "Bom trabalho!",
      "nice_try": "Errado. Tente novamente!",
      "passed_check_html": "Ótimo! Você passou pontuandp por essa verificação de conhecimento <b>%{percentage}%</b>.<br>Confira o próximo caminho de aprendizado!",
      "try_again": {
        "title": "Tente novamente",
        "text_html": "Infelizmente, você atingiu <b>%{percentage}%</b> e não passou nesta verificação de conhecimento.<br>Só falta uma tentativa; use-o com sabedoria ...",
        "review": "Por favor, revise todos os itens essenciais no caminho de aprendizagem antes de retomar a verificação de conhecimento.",
        "good_luck": "Boa sorte!"
      },
      "failed_html": "Infelizmente, você tentou duas vezes, mas não passou por essa verificação de conhecimento.<br>Você não tem mais tentativas.<br>Por favor, verifique com o seu supervisor",
      "button": {
        "close": "Verificar conhecimento"
      },
      "bonus_html": "Você respondeu corretamente mais de <b>%{percentage}%</b> das perguntas. Você recebeu <b>%{bonus_points}</b> pontos bônus!"
    },
    "report": "Ver resultados",
    "intro_text": "dê uma olhada nas perguntas abaixo para ver quais você respondeu incorretamente.",
    "all_answers_correct": "Parabéns! Você respondeu corretamente a todas as perguntas desta verificação de conhecimento.",
    "your_answers": "Suas respostas",
    "your_answer": "Sua resposta",
    "correct_answers": "Respostas corretas",
    "correct_answer": "Resposta correta"
  },
  "feedback": {
    "send": "Enviar feedback",
    "input_placeholder": "Por favor, dê o seu feedback",
    "feedback_sent": "Obrigado pelo seu feedback.",
    "feedback_error": "Seu feedback não pode ser enviado, por favor tente de novo.",
    "screenshot_prompt": "Você será solicitado a compartilhar sua tela, usaremos isso para criar uma captura de tela que será enviada junto com o feedback"
  },
  "single_sign_on": {
    "failure": "Falha ao autenticar o Login"
  }
};
I18n.translations["ja"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "すでに確認済みですので、サインインしてみてください",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "content_type_invalid": "のContent Typeが不正です",
      "file_size_out_of_range": "の容量 %{file_size} が許容範囲外です",
      "limit_out_of_range": "の数が許容範囲外です",
      "image_metadata_missing": "は不正な画像です",
      "dimension_min_inclusion": "は %{width} x %{height} ピクセル以上の大きさにしてください",
      "dimension_max_inclusion": "は %{width} x %{height} ピクセル以下の大きさにしてください",
      "dimension_width_inclusion": "の横幅は %{min} ピクセル以上 %{max} ピクセル以下にしてください",
      "dimension_height_inclusion": "の縦幅は %{min} ピクセル以上 %{max} ピクセル以下にしてください",
      "dimension_width_greater_than_or_equal_to": "の横幅は %{length} ピクセル以上にしてください",
      "dimension_height_greater_than_or_equal_to": "の縦幅は %{length} ピクセル以上にしてください",
      "dimension_width_less_than_or_equal_to": "の横幅は %{length} ピクセル以下にしてください",
      "dimension_height_less_than_or_equal_to": "の縦幅は %{length} ピクセル以下にしてください",
      "dimension_width_equal_to": "の横幅は %{length} ピクセルにしてください",
      "dimension_height_equal_to": "の縦幅は %{length} ピクセルにしてください",
      "aspect_ratio_not_square": "は正方形にしてください",
      "aspect_ratio_not_portrait": "は縦長にしてください",
      "aspect_ratio_not_landscape": "は横長にしてください",
      "aspect_ratio_is_not": "のアスペクト比は %{aspect_ratio} にしてください",
      "aspect_ratio_unknown": "のアスペクト比を取得できませんでした"
    },
    "reset_password_token_invalid": "パスワードトークンのリセットが無効です"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "Eメール",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認用）",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶する",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": "ユーザー",
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "ダッシュボード"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "管理者"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "管理者にのみ表示",
    "visible_for_admin_text": "このアイテムはまだ公開されておらず、管理者のみがこのアイテムをプレビューできます"
  },
  "essential_card": {
    "resume": "履歴書",
    "start": "開始",
    "completed": "完了"
  },
  "global": {
    "back": "バック",
    "exit": "出口",
    "min": "分",
    "favorite": "お気に入りに追加",
    "remove_favorite": "お気に入りから削除",
    "next": "次",
    "check": "小切手",
    "save": "セーブ",
    "assign_content": "コンテンツを割り当てる",
    "assign_tags": "タグを割り当てる",
    "sign_off_training": "サインオフトレーニング",
    "sign_off_list_title": "教室と課題の必需品",
    "search_content": "コンテンツを検索",
    "search_tags": "タグを検索",
    "search_training": "検索トレーニング",
    "already_completed": "％{item}はすでに完了しています！"
  },
  "unlock_by_completing": "完了してロックを解除します",
  "layout": {
    "header": {
      "navigation": {
        "home": "ホームホーム",
        "profile": "プロフィール",
        "favorites": "お気に入り",
        "learn_more": "もっと詳しく知る",
        "management_report": "経営報告"
      }
    },
    "footer": {
      "feedback": "フィードバック"
    }
  },
  "impersonate": {
    "logged_in_as": "次のユーザーとしてログイン: %{name}",
    "sign_out": "サインアウト"
  },
  "perform": {
    "title": "あなたの進歩",
    "points": "ポイント",
    "position": "あなたの位置",
    "achievements": "あなたの成果",
    "give_retries": "再試行します",
    "give_retry": {
      "button": "再試行してください",
      "search": "学習パスを検索する",
      "subtitle": "このユーザーが再試行していない学習パスを検索します"
    },
    "manager": {
      "subtitle": "経営報告",
      "title": "パフォーマンス",
      "averages": "平均",
      "position": "ポジション",
      "achievements": "実績",
      "progress": "進捗",
      "chart": {
        "progress": "学習パスの進捗状況",
        "knowledge_checks": "知識チェックスコア",
        "experience": "XPポイント"
      },
      "report": {
        "title": "レポートをダウンロード",
        "button": "csvにエクスポート"
      },
      "table": {
        "employees": "従業員",
        "points": "XPポイント",
        "progress": "進捗",
        "knowledge_checks": "知識チェック平均",
        "badges": "バッジ",
        "groupless_users": "グループに属していない（％{count}）",
        "grant_retry": "再試行してください",
        "granted_retry": "'％{learning_path_name}'のナレッジチェックを％{name}に再試行しました",
        "granted_retry_error": "再試行はできません"
      }
    }
  },
  "learn_more": {
    "title": "もっと詳しく知る",
    "subtitle": "必需品",
    "no_items": "検索条件にアイテムがありません",
    "all": "すべて",
    "search": "探す",
    "filters": {
      "assigned": "あなたに割り当てられた",
      "mandatory": "必須",
      "tags": "タグに基づく"
    }
  },
  "bookmark": {
    "title": "お気に入り",
    "subtitle": "保存したアイテム",
    "load_more": "もっと読み込む",
    "no_bookmarks_html": "現在、お気に入りはありません。%{icon} をクリックして記事をお気に入りに追加し、見つけやすい記事を含む独自の個人用ライブラリを作成してください。"
  },
  "homepage": {
    "header": {
      "your_performance": "あなたのパフォーマンス",
      "no_assigned": "割り当てられたコンテンツはありません",
      "no_assigned_explanation": "現在、まだ完了していない割り当て済みのコンテンツはありません。詳細ページに移動して、すべてのコンテンツを確認してください。"
    },
    "assigned": {
      "title": "あなたに割り当てられた",
      "button": "もっと詳しく知る"
    },
    "tag": {
      "button": "見る"
    },
    "favorites": {
      "title": "私のお気に入り",
      "button": "すべて表示"
    }
  },
  "essential": {
    "no_unlockers_html": "現在、このエッセンシャルのロックを解除できるアイテムはありません。サポートが必要な場合は、サイト管理者に連絡してください",
    "intro": {
      "start": "学習を開始します",
      "no_content": "このエッセンシャルにはチャプターアイテムがないため、開始できません。"
    },
    "general": {
      "correct": "正しい",
      "incorrect": "正しくない"
    },
    "conclusion": {
      "end": "到達しました",
      "exit": "終わり"
    },
    "class_room": {
      "title": "教室でのトレーニング",
      "reset": "トレーニングをリセット"
    },
    "assignment": {
      "title": "割り当て"
    },
    "self_study": {
      "title": "独学"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "学習パス"
    },
    "learningpaths": "学習パス",
    "title": "学習パス",
    "no_unlockers_html": "現在、この学習パスのロックを解除できるアイテムはありません。サポートが必要な場合は、サイト管理者に連絡してください",
    "knowledge_check": "知識チェック",
    "essentials": "必需品",
    "essential_add": "ここで、選択したエッセンシャルグループに追加するエッセンシャルを検索できます。",
    "essentials_message": "必需品のロックを解除するためのチャレンジを完了する",
    "tags": "タグ",
    "see_results": "結果を見る",
    "results": {
      "averages": "平均",
      "learning_path_progress": "学習パスの進捗状況",
      "knowledge_check_scores": "知識チェックスコア",
      "knowledge_check_results": "ナレッジチェック結果",
      "xp_points": "Xpポイント",
      "details": "詳細",
      "detailed": {
        "question": "質問",
        "correct_answer": "正解",
        "correct_answers": "正解",
        "correct_answers_amount": "正解額",
        "incorrect_answers": "不正解",
        "incorrect_answers_amount": "不正解額",
        "no_results": "結果がありません",
        "current_questions": "現在の質問",
        "historical_questions": "歴史的な質問"
      },
      "groups": {
        "name": "グループ名",
        "learning_path_progress": "学習パスの進捗状況",
        "first_attempt": "最初の試み",
        "last_attempt": "最後の試行",
        "achieved_learning_path": "達成された学習パス",
        "not_applicable": "該当なし",
        "groupless": "グループレス"
      }
    }
  },
  "badge": {
    "title": "実績のロックが解除されました！",
    "points": "+％{スコア}ポイント"
  },
  "download_list": {
    "download_specifications": "仕様をダウンロードする",
    "download": "ダウンロード"
  },
  "challenge": {
    "intro": {
      "title_html": "チャレンジ",
      "test": "チャレンジでテストしてください！",
      "explanation_html": "準備はできたか？あなたができる限りこれらの質問に答えるようにしてください。ショットは1つだけです。正解する質問が多いほど、より多くの重要事項が完了としてマークされます。<br>しかし、さらに多くの…<br>正解ごとに100XPポイントを獲得できます。あなたのプロフィールページをチェックして、あなたが同僚と比較してどのようにランク付けされているかを見てください！",
      "ready_set_go": "位置についてよーいどん！",
      "already_completed": "必需品を研究し続けます。",
      "retries": {
        "zero": "残りの試みはありません",
        "one": "あなたは1つの試みがあります！",
        "other": "％{count}回の試行があります！"
      }
    },
    "button": {
      "continue": "挑戦を続ける",
      "start": "チャレンジを開始"
    },
    "circle-chart": {
      "questions": "質問"
    },
    "completed": {
      "nice_try": "よい試み！",
      "good_job": "よくやった！",
      "failed_html": "まだ学ぶべきことがあるので、必需品は完成していません。<br>次の必需品を調べて勉強してください。",
      "perfect_score": "다음 주제에서 만점을 받았습니다.",
      "marked_complete": "これらのトピックは完了とマークされていますが、とにかく自由に勉強してください。",
      "answered_correctly_html": "<b>％{total} </ b>の回答のうち<b>％{correct} </ b>に正解しました。",
      "circle-chart": {
        "your-score": "あなたのスコア"
      },
      "button": {
        "close": "クローズチャレンジ",
        "try-again": "再試行"
      }
    },
    "question": {
      "helper_multiple_choice": "正しい答えを選ぶ",
      "helper_multiple_response": "複数の回答が可能",
      "question": "質問"
    },
    "points": "xpポイント"
  },
  "knowledge_check": {
    "attempts": {
      "first": "最初の試み",
      "many": "％{count}を試行します",
      "previous": "以前の試み",
      "last": "最後の試行"
    },
    "intro": {
      "title_html": "知識チェック",
      "test": "知識チェックであなたの知識をテストしてください！",
      "explanation_html": "あなたはたくさんのことを学びました。あなたが知っていることを見てみましょう。この知識チェックでは、この学習パスに関する知識を測定します。<br> <br>質問の80％に正しく答えると、学習パスは完了としてマークされ、トロフィーを受け取ります。<br>忘れないでください…あなた正解ごとに100XPポイントを獲得します。",
      "already_completed": "次の学習パスをチェックしてください！"
    },
    "button": {
      "continue": "知識チェックを続ける",
      "start": "ナレッジチェックを開始します"
    },
    "completed": {
      "good_job": "よくやった！",
      "nice_try": "残念な...",
      "passed_check_html": "すごい！ <b>％{percentage}％</ b>を獲得して、この知識チェックに合格しました。<br>次の学習パスを確認してください。",
      "try_again": {
        "title": "再試行",
        "text_html": "残念ながら、あなたは<b>％{percentage}％</ b>を獲得し、この知識チェックに合格しませんでした。<br>残りの試行は1つだけです。賢く使ってください...",
        "review": "知識チェックを再受験する前に、学習パスのすべての重要事項を確認してください。",
        "good_luck": "がんばろう！"
      },
      "failed_html": "残念ながら、2回試行しましたが、このナレッジチェックに合格しませんでした。<br>試行が残っていません。<br>チームマネージャーにチェックインしてください。",
      "button": {
        "close": "知識チェックを閉じる"
      },
      "bonus_html": "質問の<b>％{percentage}％</ b>以上に正しく回答しました。 <b>％{bonus_points} </ b>ボーナスポイントを獲得しました！"
    },
    "report": "結果を見る",
    "intro_text": "以下の質問を見て、間違って答えた質問を確認してください。",
    "all_answers_correct": "おめでとう！このナレッジチェックのすべての質問に正しく回答しました。",
    "your_answers": "あなたの答え",
    "your_answer": "あなたの答え",
    "correct_answers": "正解",
    "correct_answer": "正解"
  },
  "feedback": {
    "send": "フィードバックを送信する",
    "input_placeholder": "フィードバックをお寄せください",
    "feedback_sent": "ご意見をいただきありがとうございます。",
    "feedback_error": "フィードバックを送信できませんでした。もう一度お試しください。",
    "screenshot_prompt": "画面を共有するように求められます。これを使用して、フィードバックと一緒に送信されるスクリーンショットを作成します。"
  },
  "single_sign_on": {
    "failure": "シングルサインオンでの認証に失敗しました"
  }
};
I18n.translations["ko"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "은(는) 이미 인증되었습니다. 다시 로그인 해보세요.",
      "confirmation_period_expired": "%{period} 이내에 이메일 인증을 해야 합니다. 새로 요청해 주세요.",
      "expired": "이(가) 만료되었습니다 새로 요청해 주세요.",
      "not_found": "찾을 수 없습니다.",
      "not_locked": "은(는) 잠기지 않았습니다.",
      "not_saved": "%{count}개의 오류로 인해 %{resource}이(가) 저장되지 못했습니다.",
      "content_type_invalid": "has an invalid content type",
      "file_size_out_of_range": "size %{file_size} is not between required range",
      "limit_out_of_range": "total number is out of range",
      "image_metadata_missing": "is not a valid image",
      "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
      "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
      "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
      "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
      "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
      "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
      "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
      "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
      "dimension_width_equal_to": "width must be equal to %{length} pixel",
      "dimension_height_equal_to": "height must be equal to %{length} pixel",
      "aspect_ratio_not_square": "must be a square image",
      "aspect_ratio_not_portrait": "must be a portrait image",
      "aspect_ratio_not_landscape": "must be a landscape image",
      "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
      "aspect_ratio_unknown": "has an unknown aspect ratio"
    },
    "reset_password_token_invalid": "Reset password token is invalid"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "확인 메일 전송 시간",
        "confirmation_token": "확인 토큰",
        "confirmed_at": "확인된 시간",
        "created_at": "생성된 시간",
        "current_password": "현재 비밀번호",
        "current_sign_in_at": "현재 접속 시간",
        "current_sign_in_ip": "현재 접속 IP주소",
        "email": "이메일",
        "encrypted_password": "암호화된 비밀번호",
        "failed_attempts": "접속시도 실패",
        "last_sign_in_at": "마지막 접속 시간",
        "last_sign_in_ip": "마지막 접속 IP주소",
        "locked_at": "접근 잠김 시간",
        "password": "비밀번호",
        "password_confirmation": "비밀번호 확인",
        "remember_created_at": "로그인 기록된 시간",
        "remember_me": "로그인 정보 기억하기",
        "reset_password_sent_at": "재설정된 암호 발송",
        "reset_password_token": "재설정된 암호 토큰",
        "sign_in_count": "로그인 수",
        "unconfirmed_email": "확인되지 않은 이메일 주소",
        "unlock_token": "잠금해제 토큰",
        "updated_at": "업데이트된 시간",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": "사용자",
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "계기반"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "관리자"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "관리자에게만 표시",
    "visible_for_admin_text": "이 항목은 아직 게시되지 않았으며 관리자 만이 항목을 미리 볼 수 있습니다."
  },
  "essential_card": {
    "resume": "이력서",
    "start": "스타트",
    "completed": "완료"
  },
  "global": {
    "back": "뒤",
    "exit": "출구",
    "min": "분",
    "favorite": "즐겨 찾기에 추가",
    "remove_favorite": "즐겨 찾기에서 삭제하기",
    "next": "다음",
    "check": "검사",
    "save": "저장",
    "assign_content": "콘텐츠 할당",
    "assign_tags": "태그 할당",
    "sign_off_training": "교육 종료",
    "sign_off_list_title": "강의실 및 과제 필수 사항",
    "search_content": "콘텐츠 검색",
    "search_tags": "검색 태그",
    "search_training": "검색 교육",
    "already_completed": "%{item}이 (가) 이미 완료되었습니다!"
  },
  "unlock_by_completing": "완료하여 잠금 해제",
  "layout": {
    "header": {
      "navigation": {
        "home": "집",
        "profile": "프로필",
        "favorites": "즐겨 찾기",
        "learn_more": "더 알아보기",
        "management_report": "관리 보고서"
      }
    },
    "footer": {
      "feedback": "피드백"
    }
  },
  "impersonate": {
    "logged_in_as": "다음으로 로그인했습니다.: %{name}",
    "sign_out": "Logged in as"
  },
  "perform": {
    "title": "진도",
    "points": "포인트들",
    "position": "너의 위치",
    "achievements": "당신의 업적",
    "give_retries": "재시도",
    "give_retry": {
      "button": "재시도",
      "search": "학습 경로 검색",
      "subtitle": "이 사용자가 재 시도하지 않은 학습 경로 검색"
    },
    "manager": {
      "subtitle": "관리 보고서",
      "title": "공연",
      "averages": "평균",
      "position": "위치",
      "achievements": "업적",
      "progress": "진행",
      "chart": {
        "progress": "학습 경로 진행",
        "knowledge_checks": "지식 점검 점수",
        "experience": "XP 포인트"
      },
      "report": {
        "title": "보고서 다운로드",
        "button": "csv로 내보내기"
      },
      "table": {
        "employees": "직원",
        "points": "XP 포인트",
        "progress": "진행",
        "knowledge_checks": "지식 검사 평균",
        "badges": "배지",
        "groupless_users": "그룹에 속하지 않음 (%{count})",
        "grant_retry": "다시 시도하십시오",
        "granted_retry": "%{name}에게 '%{learning_path_name}'의 지식 검사를 다시 시도했습니다.",
        "granted_retry_error": "재 시도는 불가능합니다."
      }
    }
  },
  "learn_more": {
    "title": "더 알아보기",
    "subtitle": "본질",
    "no_items": "검색 기준에 항목이 없습니다",
    "all": "모두",
    "search": "검색",
    "filters": {
      "assigned": "당신에게 할당 됨",
      "mandatory": "필수",
      "tags": "태그 기반"
    }
  },
  "bookmark": {
    "title": "즐겨 찾기",
    "subtitle": "저장된 항목",
    "load_more": "더로드",
    "no_bookmarks_html": "현재 즐겨 찾기가 없습니다. %{icon} 를 클릭하여 기사를 즐겨 찾기에 추가하면 기사를 쉽게 찾을 수있는 개인 라이브러리를 만들 수 있습니다."
  },
  "homepage": {
    "header": {
      "your_performance": "당신의 성과",
      "no_assigned": "할당 된 콘텐츠 없음",
      "no_assigned_explanation": "현재 아직 완료하지 않은 할당 된 콘텐츠가 없습니다. 자세히 알아보기 페이지로 이동하여 모든 콘텐츠를 살펴보세요."
    },
    "assigned": {
      "title": "당신에게 할당 됨",
      "button": "더 알아보기"
    },
    "tag": {
      "button": "전망"
    },
    "favorites": {
      "title": "내가 좋아하는 것들",
      "button": "모두보기"
    }
  },
  "essential": {
    "no_unlockers_html": "현재이 필수 항목을 잠금 해제 할 수있는 항목이 없습니다. 도움이 필요하면 사이트 관리자에게 문의하십시오.",
    "intro": {
      "start": "학습 시작",
      "no_content": "이 필수 항목에는 챕터 항목이 없으므로 시작할 수 없습니다."
    },
    "general": {
      "correct": "옳은",
      "incorrect": "잘못된"
    },
    "conclusion": {
      "end": "도달했습니다",
      "exit": "끝"
    },
    "class_room": {
      "title": "교실 교육",
      "reset": "교육 재설정"
    },
    "assignment": {
      "title": "할당"
    },
    "self_study": {
      "title": "자기 연구"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "학습 경로"
    },
    "learningpaths": "학습 경로",
    "title": "학습 경로",
    "no_unlockers_html": "현재이 학습 경로를 잠금 해제 할 수있는 항목이 없습니다. 도움이 필요하면 사이트 관리자에게 문의하십시오.",
    "knowledge_check": "지식 점검",
    "essentials": "골자",
    "essential_add": "여기에서 선택한 필수 그룹에 추가 할 필수 항목을 검색 할 수 있습니다.",
    "essentials_message": "도전을 완료하여 필수품 잠금 해제",
    "tags": "태그",
    "see_results": "결과보기",
    "results": {
      "averages": "평균",
      "learning_path_progress": "학습 경로 진행",
      "knowledge_check_scores": "지식 점검 점수",
      "knowledge_check_results": "지식 점검 결과",
      "xp_points": "XP 포인트",
      "details": "세부",
      "detailed": {
        "question": "질문",
        "correct_answer": "정답",
        "correct_answers": "올바른 답",
        "correct_answers_amount": "정답 금액",
        "incorrect_answers": "오답",
        "incorrect_answers_amount": "오답 금액",
        "no_results": "결과 없음",
        "current_questions": "현재 질문",
        "historical_questions": "역사적 질문"
      },
      "groups": {
        "name": "그룹 이름",
        "learning_path_progress": "학습 경로 진행",
        "first_attempt": "첫번째 시도",
        "last_attempt": "마지막 시도",
        "achieved_learning_path": "달성 된 학습 경로",
        "not_applicable": "해당 없음",
        "groupless": "그룹리스"
      }
    }
  },
  "badge": {
    "title": "잠금 해제 업적!",
    "points": "+ %{score} 점"
  },
  "download_list": {
    "download_specifications": "사양 다운로드",
    "download": "다운로드"
  },
  "challenge": {
    "intro": {
      "title_html": "도전",
      "test": "도전에서 테스트 해보세요!",
      "explanation_html": "많은 것을 배웠습니다. 알고있는 것을 보도록하겠습니다.이 지식 확인은이 학습 경로에 대한 지식을 측정합니다. <br> <br> 문제의 80 %에 올바르게 답하면 학습 경로가 완료로 표시되고 트로피를받습니다. <br> 잊지 마세요… 정답마다 100 XP 포인트를받습니다. ",
      "ready_set_go": "준비… 설정…가!",
      "already_completed": "본질을 계속 연구하십시오.",
      "retries": {
        "zero": "남은 시도가 없습니다",
        "one": "한 번 시도했습니다!",
        "other": "%{count} 번 시도했습니다!"
      }
    },
    "button": {
      "continue": "계속 도전",
      "start": "도전 시작"
    },
    "circle-chart": {
      "questions": "질문"
    },
    "completed": {
      "nice_try": "좋은 시도!",
      "good_job": "잘 했어!",
      "failed_html": "아직 배울 것이있어서 필수 항목이 아직 완료되지 않았습니다. <br> 다음 필수 항목을 탐색하고 연구하세요.",
      "perfect_score": "다음 주제에서 만점을 받았습니다.",
      "marked_complete": "これらのトピックは完了とマークされていますが、とにかく自由に勉強してください。",
      "answered_correctly_html": "<b> %{total} </ b> 개의 답변 중 <b> %{correct} </ b> 개를 올바르게 답변했습니다.",
      "circle-chart": {
        "your-score": "너의 점수"
      },
      "button": {
        "close": "가까운 도전",
        "try-again": "다시 시도하십시오"
      }
    },
    "question": {
      "helper_multiple_choice": "정답 선택",
      "helper_multiple_response": "여러 답변 가능",
      "question": "질문"
    },
    "points": "XP 포인트"
  },
  "knowledge_check": {
    "attempts": {
      "first": "첫번째 시도",
      "many": "%{count} 시도",
      "previous": "이전 시도",
      "last": "마지막 시도"
    },
    "intro": {
      "title_html": "지식 점검",
      "test": "지식 점검에서 지식을 테스트하십시오!",
      "explanation_html": "많은 것을 배웠습니다. 알고있는 것을 보도록하겠습니다.이 지식 확인은이 학습 경로에 대한 지식을 측정합니다. <br> <br> 문제의 80 %에 올바르게 답하면 학습 경로가 완료로 표시되고 트로피를받습니다. <br> 잊지 마세요… 정답마다 100 XP 포인트를받습니다. ",
      "already_completed": "다음 학습 경로를 확인하십시오!"
    },
    "button": {
      "continue": "지식 확인 계속",
      "start": "지식 확인 시작"
    },
    "completed": {
      "good_job": "잘 했어!",
      "nice_try": "너무 나쁘다 ...",
      "passed_check_html": "좋아요! <b> %{percentage} % </ b>로 지식 검사를 통과했습니다. <br> 다음 학습 경로를 확인하세요!",
      "try_again": {
        "title": "다시 시도하십시오",
        "text_html": "안타깝게도 <b> %{percentage} % </ b> 점을 얻었으며이 지식 검사를 통과하지 못했습니다. <br> 한 번의 시도 만 남았습니다. 현명하게 사용하십시오 ...",
        "review": "지식 점검을 다시 수행하기 전에 학습 경로의 모든 필수 사항을 검토하십시오.",
        "good_luck": "행운을 빕니다!"
      },
      "failed_html": "안타깝게도 두 번 시도했지만이 지식 확인을 통과하지 못했습니다. <br> 남은 시도가 없습니다. <br> 팀 관리자에게 확인하십시오.",
      "button": {
        "close": "지식 확인 닫기"
      },
      "bonus_html": "질문의 <b> %{percentage} % </ b> 이상을 정확하게 답하셨습니다. <b> %{bonus_points} </ b> 보너스 포인트를 받았습니다!"
    },
    "report": "결과보기",
    "intro_text": "어떤 질문에 틀린 답을했는지 아래 질문을 살펴보십시오.",
    "all_answers_correct": "축하합니다!이 지식 점검의 모든 질문에 올바르게 답하셨습니다.",
    "your_answers": "당신의 대답",
    "your_answer": "너의 답",
    "correct_answers": "올바른 답",
    "correct_answer": "정답"
  },
  "feedback": {
    "send": "의견 보내기",
    "input_placeholder": "의견을 보내주세요",
    "feedback_sent": "귀하의 의견에 감사드립니다.",
    "feedback_error": "귀하의 의견을 보낼 수 없습니다. 다시 시도하십시오.",
    "screenshot_prompt": "화면을 공유하라는 메시지가 표시됩니다. 이것을 사용하여 피드백과 함께 보낼 스크린 샷을 만들 것입니다."
  },
  "single_sign_on": {
    "failure": "Single Sign On으로 인증하지 못했습니다."
  }
};
I18n.translations["zh-CN"] = {
  "time": {
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "am": "AM",
    "pm": "PM"
  },
  "support": {
    "array": {
      "words_connector": ", ",
      "two_words_connector": " and ",
      "last_word_connector": ", and "
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "already_confirmed": "已确认，请尝试登录",
      "confirmation_period_expired": "注册帐号后须在%{period}以内确认。请重新注册。",
      "expired": "邮件确认已过期，请重新注册。",
      "not_found": "找不到",
      "not_locked": "未被锁定",
      "not_saved": {
        "one": "发生 1 个错误，导致%{resource}保存失败：",
        "other": "发生 %{count} 个错误，导致%{resource}保存失败："
      },
      "content_type_invalid": "has an invalid content type",
      "file_size_out_of_range": "size %{file_size} is not between required range",
      "limit_out_of_range": "total number is out of range",
      "image_metadata_missing": "is not a valid image",
      "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
      "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
      "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
      "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
      "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
      "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
      "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
      "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
      "dimension_width_equal_to": "width must be equal to %{length} pixel",
      "dimension_height_equal_to": "height must be equal to %{length} pixel",
      "aspect_ratio_not_square": "must be a square image",
      "aspect_ratio_not_portrait": "must be a portrait image",
      "aspect_ratio_not_landscape": "must be a landscape image",
      "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
      "aspect_ratio_unknown": "has an unknown aspect ratio"
    },
    "reset_password_token_invalid": "重置密码令牌无效"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        },
        "is_not_attached": "is not attached",
        "corrupt": "is corrupt",
        "end_after_start": "Start can not be after end",
        "content_type_invalid": "has type %{content_type}, only %{authorized_types} is allowed",
        "custom_file_size_out_of_range": "size is more than %{file_size}",
        "not_supported_by_page": "is not supported by the selected page"
      },
      "models": {
        "essential": {
          "not_published": "Essential could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the essential itself."
        },
        "learning_path": {
          "not_published": "Learning path could not be published, please correct the error(s) shown below.",
          "errors_found": "errors found in the learning path itself.",
          "not_all_essentials_published": "not all essentials are published"
        },
        "chapter_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "chapter_items/multiple_response": {
          "attributes": {
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "chapter_items/external_source": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/video": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/pdf_download": {
          "attributes": {
            "media_item": {
              "invalid_type": "invalid type"
            }
          }
        },
        "chapter_items/hotspot": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/flip": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "chapter_items/user_answer": {
          "attributes": {
            "answer_titles": {
              "minimum_correct_answers": "Answer should have at least one item"
            },
            "user": {
              "unique": "You have already answered this question"
            }
          }
        },
        "challenge_items": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "minimum_answers": "should be minimum 2.",
              "maximum_answers": "should be maximum 4."
            },
            "correct_answer": {
              "only_one": "should only have one correct answer",
              "minimum_correct_answers": "should have at least one item"
            },
            "positive_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "negative_feedback": {
              "empty_title": "title can't be blank",
              "empty_text": "text can't be blank"
            },
            "hotspot_items": {
              "minimum_items": "%{count} items required"
            },
            "sources": {
              "minimum_sources": "should have at least one item"
            }
          }
        },
        "challenge_items/multiple_choice": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "only_one": "should only have one correct answer"
            }
          }
        },
        "challenge_items/multiple_response": {
          "attributes": {
            "answers": {
              "unique": "should all be unique values",
              "maximum_answers": "should be maximum 4.",
              "minimum_answers": "should be minimum 2."
            },
            "correct_answers": {
              "minimum_correct_answers": "should have at least one item"
            }
          }
        },
        "media_items/image": {
          "attributes": {
            "image": {
              "analyze_error": "could not be analyzed, image is probably too large"
            }
          }
        }
      }
    },
    "attributes": {
      "user": {
        "confirmation_sent_at": "确认邮件发送于",
        "confirmation_token": "确认令牌",
        "confirmed_at": "确认于",
        "created_at": "创建于",
        "current_password": "当前密码",
        "current_sign_in_at": "最近登录于",
        "current_sign_in_ip": "最近登录 IP",
        "email": "电子邮箱",
        "encrypted_password": "密码被加密",
        "failed_attempts": "失败尝试",
        "last_sign_in_at": "最后登录于",
        "last_sign_in_ip": "最后登录的 IP",
        "locked_at": "锁定于",
        "password": "密码",
        "password_confirmation": "密码确认",
        "remember_created_at": "记住创建于",
        "remember_me": "记住登录信息",
        "reset_password_sent_at": "重置密码发送于",
        "reset_password_token": "密码重置令牌",
        "sign_in_count": "登录次数",
        "unconfirmed_email": "未确认邮件",
        "unlock_token": "帐户解锁令牌",
        "updated_at": "更新于",
        "full_name": "Full name",
        "first_name": "First name",
        "last_name": "Last name",
        "learning_paths": "Assigned learning paths",
        "essentials": "Assigned essentials",
        "role": "Role",
        "site": "Site",
        "avatar": "Avatar",
        "deleted_at": "Archived",
        "search_users": "Search users",
        "select_users": "Select users",
        "no_users_with_search_criteria": "There are no users within your search criteria"
      },
      "user_import": {
        "file": "filename",
        "user": "imported by",
        "result": "result",
        "status": "Import status",
        "created_at": "created",
        "successfully_imported": "Successfully imported",
        "invalid_users": "Invalid users",
        "existing_imported": "Existing users"
      },
      "region": {
        "title": "Title",
        "locale": "locale",
        "salesforce_region_id": "Salesforce region id",
        "search": "Search for regions",
        "languages": "Languages"
      },
      "vendor": {
        "title": "Title",
        "region": "Region",
        "sites": "sites of vendor",
        "salesforce_vendor_id": "Salesforce Vendor ID"
      },
      "site": {
        "title": "Title",
        "vendor": "Vendor",
        "salesforce_site_id": "Salesforce site id",
        "users": "users of site"
      },
      "learning_path": {
        "title": "Title",
        "by": "By",
        "introduction": "Introduction",
        "tags": "Tags",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "knowledge_check_enabled": "knowledge check enabled",
        "knowledge_check_text": "Knowledge check text",
        "knowledge_check_image": "Knowledge check image",
        "content_image": "Content image",
        "image": "Image",
        "challenge_text": "Challenge text",
        "challenge_image": "Challenge image",
        "unlock_behaviour": "unlock behaviour",
        "challenge": "challenge",
        "essential_groups_locked": "essential groups are locked",
        "essential_groups_unlocked": "essential groups are unlocked",
        "hints": {
          "challenge": "If you choose this, then there will be a challenge in the learning path. After finishing, the essentials are all unlocked",
          "essential_groups_locked": "First one is unlocked, all others after it get unlocked by following the previous one",
          "essential_groups_unlocked": "Every essential group is unlocked. The essentials within the group can be locked individually"
        },
        "no_learning_paths_available": "No learning paths available",
        "search_for_learning_paths": "Search for learning paths",
        "select_learning_paths": "Select learning paths",
        "no_learning_paths_with_search_criteria": "There are no learning paths within your search criteria"
      },
      "essential_group": {
        "title": "Title",
        "learning_path": "Learning path"
      },
      "item_picker": {
        "no_items": "Currently there are no items that will unlock this, please add them with the button below",
        "no_users": "Currently there are no users in this group, please add them with the button below"
      },
      "essential": {
        "title": "Title",
        "by": "By",
        "creator": "Creator",
        "essential_type": "Type",
        "essential_group": "essential group",
        "duration": "duration",
        "tags": "tags",
        "intro_title": "Introduction title",
        "intro_body": "Introduction body",
        "conclusion_title": "Conclusion title",
        "conclusion_body": "Conclusion body",
        "chapter_items": "Educational templates",
        "chapter_item_title": "Title",
        "chapter_item_subtitle": "Subtitle",
        "chapter_item_type": "Chapter item type",
        "challenge_items": "Challenge and knowledge items",
        "image": "Image",
        "content_image": "Content image",
        "locked_by_default": "Locked by default",
        "mandatory_assigned": "Mandatory assigned",
        "no_essentials_available": "No essentials available",
        "search_essentials": "Search essentials",
        "select_essentials": "Select essentials",
        "no_essentials_with_search_criteria": "There are no essentials within your search criteria"
      },
      "tag": {
        "title": "Title",
        "intro_title": "Intro Title",
        "body": "Body",
        "image": "Image",
        "content_image": "Content Image"
      },
      "taggable": {
        "no_tags_available": "No tags available",
        "search_for_tags": "Search for tags"
      },
      "group": {
        "title": "title",
        "creator": "creator",
        "site": "site",
        "users": "users"
      },
      "media_folder": {
        "title": "title"
      },
      "media_item": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/image": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/vimeo": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/youtube": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "media_items/interactive_video": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color",
        "zip_archive": "Zip Archive"
      },
      "media_items/pdf": {
        "title": "title",
        "media_item_type": "type",
        "media_folder": "folder",
        "image": "image",
        "video": "video",
        "video_url": "video url",
        "pdf": "pdf",
        "updated_at": "last updated",
        "processed": "processed",
        "processing_errors": "processing errors",
        "text_color": "Text color"
      },
      "chapter_item": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/text_landscape": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/video": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/hotspot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "position": "horizontal and vertical percentages"
      },
      "chapter_items/multiple_choice": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "correct_checkbox": "correct answer?"
      },
      "chapter_items/multiple_response": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/external_source": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "sources": "sources",
        "source_title": "title",
        "source_subtitle": "subtitle",
        "source_duration": "duration",
        "source_url": "url",
        "source_document_type": "document type"
      },
      "chapter_items/open_question": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "chapter_items/quote": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "author": "author",
        "intro_title": "intro title"
      },
      "chapter_items/flip": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "flip_items": "flip items",
        "correct": "correct"
      },
      "chapter_items/pdf_download": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "pdf": "pdf"
      },
      "chapter_items/chatbot": {
        "title": "title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat"
      },
      "challenge_item": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "challenge_items/multiple_choice": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type",
        "correct_checkbox": "correct answer?"
      },
      "challenge_items/multiple_response": {
        "title": "Title",
        "subtitle": "subtitle",
        "essential": "essential",
        "image": "image",
        "image_position": "image position",
        "text": "text portrait",
        "text_landscape": "text landscape",
        "video": "video",
        "body": "body",
        "chapter_item_type": "chapter item type",
        "background_color": "background color",
        "answers": "answers",
        "correct_answer": "correct answer",
        "positive_feedback_title": "positive feedback title",
        "positive_feedback_text": "positive feedback text",
        "negative_feedback_title": "negative feedback title",
        "negative_feedback_text": "negative feedback text",
        "hotspots": "hotspots",
        "open_question": "open question",
        "quote": "quote",
        "pdf_download": "pdf download",
        "chatbot": "chat",
        "challenge_item_type": "Challenge/Knowledge item type"
      },
      "publishable": {
        "published": "Published"
      }
    },
    "models": {
      "user": "用户",
      "region": "Region",
      "vendor": "Vendor",
      "site": "Site",
      "learning_path": "Learning path",
      "essential_group": "Essential Group",
      "media_folder": "Folder",
      "media_item": "Media Item",
      "media_items/image": "Image",
      "media_items/video": "Video",
      "media_items/vimeo": "Vimeo",
      "media_items/youtube": "Youtube",
      "media_items/interactive_video": "Interactive video",
      "media_items/pdf": "PDF",
      "essential": "Essential",
      "chapter_item": "Chapter Item",
      "chapter_items/text": "Text with portrait image",
      "chapter_items/text_landscape": "Text with landscape image",
      "chapter_items/video": "Video",
      "chapter_items/hotspot": "Hotspot",
      "chapter_items/multiple_choice": "Multiple Choice",
      "chapter_items/multiple_response": "Multiple Response",
      "chapter_items/external_source": "External Source",
      "chapter_items/open_question": "Open Question",
      "chapter_items/quote": "Quote",
      "chapter_items/flip": "Flip",
      "chapter_items/pdf_download": "PDF Download",
      "chapter_items/chatbot": "Chat",
      "challenge_item": "Challenge / knowledge item",
      "challenge_items/text": "Text",
      "challenge_items/video": "Video",
      "challenge_items/hotspot": "Hotspot",
      "challenge_items/multiple_choice": "Multiple Choice",
      "challenge_items/multiple_response": "Multiple Response",
      "answer": "Answer",
      "group": "Group",
      "user_import": "User Import",
      "tag": "Tag",
      "feedback_rule": "Feedback Rule"
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}"
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    }
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "previous": "&lsaquo; Prev",
      "next": "Next &rsaquo;",
      "truncate": "&hellip;"
    }
  },
  "blocks": {
    "admin": {
      "add": "Add",
      "archive": "Archive",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "cancel": "Cancel",
      "close": "Close",
      "copy": "Copy",
      "create": "Create",
      "create_muted": "Create without notifications",
      "delete": "Delete",
      "depublish": "Depublish",
      "detail": "Show %{model}",
      "edit": "Edit",
      "editing": "Editing %{model}",
      "errors": {
        "general_error": "Something went wrong, please try again",
        "prevented": {
          "one": "prevented",
          "other": "prevented"
        },
        "validation_error": "Something went wrong with the validation of this field, please try again"
      },
      "filter": "Filter",
      "filters": {
        "button_text": "Filter",
        "expansion_title": "Filters",
        "organization": "Organization",
        "role": "Role",
        "site": "Site",
        "region": "Region",
        "roles": {
          "global": "Global",
          "region": "Region",
          "site_admin": "Site admin",
          "user": "User"
        }
      },
      "flash_messages": {
        "delete_failed": "%{model} could not be deleted",
        "successfully_archived": "%{model} was successfully archived",
        "successfully_created": "%{model} was successfully created",
        "successfully_deleted": "%{model} was successfully deleted",
        "successfully_updated": "%{model} was successfully updated"
      },
      "link_rule": "Pay attention: the link should contain http:// or https://",
      "listing": "Listing %{model}",
      "manage": "Manage content",
      "minutes_shorthand": "min",
      "new": "New %{model}",
      "optional": "optional",
      "please_select": "Please select %{model}",
      "prevented_save": "%{errors} prohibited this %{model} from being saved:",
      "preview": "Preview",
      "publications": "Publications",
      "publish": "Publish",
      "published": "Published",
      "register": "Register",
      "register_muted": "Register without notifications",
      "root": {
        "root": "Admin panel"
      },
      "save": "Save",
      "save_changes": "Save changes",
      "save_changes_muted": "Save without notifications",
      "saved": "Changes saved",
      "search": "Search",
      "select": "Select",
      "short_edit": "Edit",
      "show": "Show",
      "start": "Start",
      "title": "Admin",
      "unpublished": "Unpublished"
    }
  },
  "languages": {
    "zh-CN": "Chinese (Simplified)",
    "en": "English",
    "tr": "Turkish",
    "es": "Spanish",
    "ru": "Russian",
    "pt": "Portuguese",
    "ja": "Japanese",
    "ko": "Korean"
  },
  "true": "Yes",
  "false": "No",
  "admin": {
    "adopt": "Adopt",
    "adopted": "Adopted",
    "editing": "Editing %{model}",
    "show": "Show",
    "save": "Save",
    "saved": "Changes saved",
    "start": "Start",
    "preview": "Preview",
    "detail": "Show %{model}",
    "back": "Back",
    "new": "New %{model}",
    "listing": "Listing %{model}",
    "title": "Title",
    "text": "Text",
    "author": "Author",
    "body": "Body",
    "subtitle": "Subtitle",
    "answers": "Answers",
    "answer": "Answer",
    "type": "Type",
    "duration": "Duration",
    "close": "Close",
    "add": "Add",
    "delete": "Delete",
    "copy": "Copy",
    "edit": "Edit",
    "search": "Search",
    "manage": "Manage content",
    "archive": "Archive",
    "publish": "Publish",
    "impersonate": "Impersonate",
    "are_you_sure": "Are you sure?",
    "prevented_save": "%{errors} prohibited this %{model} from being saved:",
    "please_select": "Please select %{model}",
    "errors": {
      "general_error": "Something went wrong, please try again",
      "validation_error": "Something went wrong with the validation of this field, please try again"
    },
    "regions": {
      "region": "Region",
      "regions": "regions",
      "select_region": "Select region"
    },
    "vendors": {
      "vendor": "Vendor",
      "vendors": "vendors"
    },
    "sites": {
      "site": "Site",
      "sites": "sites"
    },
    "tags": {
      "tag": "tag",
      "tags": "tags",
      "tags_explanation": "The tag fields will be used in a content block. This block will eventually appear on the home screen of a user."
    },
    "learning_paths": {
      "title": "Learning paths",
      "learning_path": "Learning path",
      "learning_paths": "learning paths",
      "published_learning_paths": "published learning paths",
      "edit": "Edit learning path",
      "published": "This learning path is published",
      "unlockers": "unlocked by",
      "order_saved": "Order saved",
      "assign": "Assign learning paths",
      "confirm_unpublished_essentials": "Publish essentials within selected region that haven't been published yet?",
      "contains_unpublished_essentials": "This learning path contains essentials that aren't published yet in the selected region, by pressing yes they will be published in the selected region if there are no errors.",
      "unlockable_by": "The following essentials will unlock your selected essential, please make sure that this learning path contains one of these essentials to make sure your selected essential is unlockable",
      "select_unlockable_by": "Unlocked by"
    },
    "essential_groups": {
      "essential_group": "essential group",
      "essential_groups": "essential groups",
      "remove_essential_group": "Delete essential group?"
    },
    "progress_records": {
      "progress_record": "progress record",
      "progress_records": "progress records"
    },
    "translate": {
      "from": "Translate from %{language}"
    },
    "essentials": {
      "title": "Essentials",
      "essential": "Essential",
      "essentials": "essentials",
      "edit": "Edit essential",
      "published": "This essential is published",
      "unlockers": "unlocked by",
      "assign": "Assign essentials",
      "image_explanation": "This image will be shown on the card",
      "content_image_explanation": "This image will be shown on the detail page itself",
      "index": {
        "no_essentials_available": "There are no essentials available, please create a new essential"
      }
    },
    "medialibrary": {
      "medialibrary": "Medialibrary"
    },
    "media_folders": {
      "media_folder": "folder"
    },
    "media_items": {
      "media_item": "media item",
      "media_item/image": "image",
      "media_item/video": "video",
      "media_item/vimeo": "vimeo video",
      "media_item/youtube": "youtube video",
      "media_item/interactive_video": "interactive video",
      "media_item/pdf": "pdf",
      "upload_status": "Upload status"
    },
    "chapter_items": {
      "correct": "Correct",
      "positive_feedback": "Positive feedback",
      "negative_feedback": "Negative feedback",
      "hotspot_items": "Hotspot items",
      "hotspot_item": "Hotspot item",
      "horizontal_percentage": "Horizontal percentage",
      "vertical_percentage": "Vertical percentage",
      "video_url": "Video url",
      "chapter_item": "chapter item",
      "chapter_items": "Educational Templates",
      "chapter_item/text": "text with portrait image",
      "chapter_item/text_landscape": "text with landscape image",
      "chapter_item/video": "text with video",
      "chapter_item/multiple_choice": "multiple choice",
      "chapter_item/multiple_response": "multiple response",
      "chapter_item/hotspot": "hotspot",
      "chapter_item/external_source": "external source",
      "chapter_item/open_question": "open question",
      "chapter_item/quote": "quote",
      "chapter_item/flip": "flip card",
      "chapter_item/pdf_download": "pdf",
      "chapter_item/chatbot": "chatbot"
    },
    "challenge_items": {
      "challenge_item": "challenge / knowledge item",
      "challenge_items": "challenge and knowledge items",
      "challenge_item/multiple_choice": "multiple choice",
      "challenge_item/multiple_response": "multiple response",
      "challenge_item/hotspot": "hotspot",
      "challenge_item/external_source": "external source"
    },
    "interactive_video": {
      "label": "Interactive video url",
      "fallback_error_message": "Something went wrong, please try again",
      "alert": "Upload your .zip file with an index.html inside",
      "found": "The provided interactive video url is correct"
    },
    "users": {
      "user": "user",
      "users": "users",
      "cleared_assigned_content": "Selected content has been cleared because you picked a different region",
      "archive_user_sure": "Are you sure?",
      "archive_user_sure_subtitle": "You can't unarchive once the user is archived",
      "profile": "Profile",
      "without_group": "Users without a group"
    },
    "groups": {
      "group": "group",
      "groups": "groups",
      "content_sign_off_button": "Select essential and sign off",
      "sign_off_button": "Sign off",
      "select_essential_button": "Select essential",
      "content_graded_notification": "\"%{content}\" has been signed off"
    },
    "user_imports": {
      "user_import": "user import",
      "user_imports": "user imports",
      "show": {
        "error_header": "An error has occurred!",
        "invalid_users_remark": "Invalid values are removed before importing",
        "not_linked_yet": "Not linked yet"
      },
      "new": {
        "template_and_instructions": "Template and instructions",
        "csv_info": "Add a csv file with a header and eight columns.\nFirstname, Lastname, Emailaddress, Avatar(from 1 to 10), Site(title exactly matching), Role(%{role}), Learningpaths(title exactly matching),  Essentials(title exactly matching).\nFor adding multiple essentials and/ or learning paths add a comma (,)  between the names of the essentials or learning paths.\n"
      },
      "invalid_headers": "Invalid headers, please use: %{headers}",
      "file_not_attached": "File is not attached"
    },
    "dashboard": {
      "average_essential_progress": "Average essential progress",
      "average_learning_path_progress": "Average learning path progress",
      "total_amount_of_achievements": "Total amount of achievements",
      "total_xp_points": "Total XP points",
      "user_assigned": "User assigned",
      "group_assigned": "Group asssigned",
      "user_completed": "User completed",
      "search_for_learning_paths": "Search learning paths",
      "created_essentials": "Created essentials",
      "created_learning_paths": "Created learning paths",
      "parent_learning_paths": "Global learning paths published in this region",
      "select_filters_learning_path_individual": "Please select a learning path and a group to see more information",
      "select_filters_learning_path": "Please select a learning path and a group or multiple groups to see more information",
      "select_filters_essential_individual": "Please select an essential and a group to see more information",
      "select_filters_essential": "Please select an essential and a group or multiple groups to see more information",
      "progress_on_essential": "Progress on essential:",
      "progress_on_learning_path": "Progress on learning path:",
      "assigned": "Assigned",
      "self_assigned": "Self assigned",
      "not_assigned": "Not assigned",
      "mandatory_assigned": "Mandatory",
      "direct_assigned": "Direct Assigned",
      "tag_assigned": "Tag Assigned",
      "direct_group_assigned": "Direct Group Assigned",
      "tag_group_assigned": "Tag Group Assigned",
      "groupless": "Groupless",
      "dashboard": "仪表 板"
    },
    "confirmation_modal": {
      "title": "Are you sure?",
      "subtitle": "This action will overwrite the existing content from this language"
    },
    "chatbot": {
      "label": "Chatbot identifier",
      "not_found": "We couldn't find a chatbot with that identifier, please check your identifier.",
      "found": "We found a chatbot with that identifier."
    },
    "vimeo": {
      "not_found": "The provided Vimeo url is not valid, please check your url",
      "found": "The Vimeo url is correct"
    },
    "feedback_rules": {
      "feedback_rules": "Feedback Rules",
      "feedback_rule": "Feedback Rule",
      "search": "Test rules with this url",
      "explanation": "Feedback rules can be used to send feedback from specific pages to different email addresses.",
      "region_explanation": "The selected region will be matched to the region that created the content. When no region is selected the rule will match regardless of who created the content. Regions can only be selected for the pages Learning path, blended learning path, knowledge check, challenge and essential.",
      "searched_for_html": "You searched for matching rules which contain \"<strong>%{url_to_match}</strong>\". To return to all rules click %{link} or click the button below.",
      "here": "here",
      "show_all_results": "Show all results",
      "matching_root_path": "Matching root path",
      "invalid_url": "Given URL to match is not valid"
    },
    "layout": {
      "header": {
        "nav": {
          "regions": "Regions",
          "vendors": "Vendors",
          "sites": "Sites",
          "learning_paths": "Learning paths",
          "essential_groups": "Essential Groups",
          "essentials": "Essentials",
          "chapter_items": "Chapter Items",
          "users": "Users",
          "user_imports": "User Imports",
          "media_items": "Media",
          "tags": "Tags",
          "groups": "Groups",
          "log_out": "Log out"
        },
        "title": "管理"
      },
      "footer": {
        "application": "Application"
      }
    },
    "visible_for_admin": "仅对管理员可见",
    "visible_for_admin_text": "此项目尚未发布，只有管理员可以预览此项目"
  },
  "essential_card": {
    "resume": "恢复",
    "start": "开始",
    "completed": "完成"
  },
  "global": {
    "back": "返回",
    "exit": "退出",
    "min": "分钟",
    "favorite": "添加到收藏夹",
    "remove_favorite": "从收藏夹中删除",
    "next": "下一个",
    "check": "检查",
    "save": "救",
    "assign_content": "分配内容",
    "assign_tags": "分配标记",
    "sign_off_training": "注销培训",
    "sign_off_list_title": "班级和作业要点",
    "search_content": "搜索内容",
    "search_tags": "搜索标记",
    "search_training": "搜索标记",
    "already_completed": "%{item} 已经完成！"
  },
  "unlock_by_completing": "完成解锁",
  "layout": {
    "header": {
      "navigation": {
        "home": "家",
        "profile": "配置 文件",
        "favorites": "收藏 夹",
        "learn_more": "了解更多信息",
        "management_report": "管理报告"
      }
    },
    "footer": {
      "feedback": "反馈"
    }
  },
  "impersonate": {
    "logged_in_as": "登录为: %{name}",
    "sign_out": "登出"
  },
  "perform": {
    "title": "您的进度",
    "points": "点",
    "position": "您的职位",
    "achievements": "您的成就",
    "give_retries": "重试",
    "give_retry": {
      "button": "重试",
      "search": "搜索学习路径",
      "subtitle": "搜索该用户没有重试的学习路径"
    },
    "manager": {
      "subtitle": "管理报告",
      "title": "性能",
      "averages": "平均",
      "position": "位置",
      "achievements": "成就",
      "progress": "进展",
      "chart": {
        "progress": "学习路径进度",
        "knowledge_checks": "知识检查分数",
        "experience": "XP 点"
      },
      "report": {
        "title": "下载报告",
        "button": "导出到 csv"
      },
      "table": {
        "employees": "员工",
        "points": "XP 点",
        "progress": "进展",
        "knowledge_checks": "知识检查平均值",
        "badges": "徽章",
        "groupless_users": "不在组中（%{count}）",
        "grant_retry": "重试",
        "granted_retry": "您已对 ％{name} 进行了重试，以进行 '％{learning_path_name}' 的知识检查",
        "granted_retry_error": "重试是不可能的"
      }
    }
  },
  "learn_more": {
    "title": "了解更多信息",
    "subtitle": "要点",
    "no_items": "您的搜索条件中没有项目",
    "all": "所有",
    "search": "搜索",
    "filters": {
      "assigned": "分配给您",
      "mandatory": "强制性",
      "tags": "根据您的标记"
    }
  },
  "bookmark": {
    "title": "添加到收藏夹",
    "subtitle": "保存的项目",
    "load_more": "加载更多",
    "no_bookmarks_html": "单击 ％{icon} 以收藏文章，以创建易于查找的文章的个人图书馆。"
  },
  "homepage": {
    "header": {
      "your_performance": "您的表现",
      "no_assigned": "未分配内容",
      "no_assigned_explanation": "您当前没有任何尚未完成的分配内容，请访问\"了解更多\"页面浏览所有内容。."
    },
    "assigned": {
      "title": "分配给您",
      "button": "了解更多信息"
    },
    "tag": {
      "button": "视图"
    },
    "favorites": {
      "title": "我的最爱",
      "button": "查看所有"
    }
  },
  "essential": {
    "no_unlockers_html": "目前没有可用于解锁此基本内容的项目。请联系您的网站管理员寻求帮助",
    "intro": {
      "start": "开始学习",
      "no_content": "这个必备没有章节项目，因此无法启动。"
    },
    "general": {
      "correct": "正确",
      "incorrect": "错误"
    },
    "conclusion": {
      "end": "你已经到达",
      "exit": "结束"
    },
    "class_room": {
      "title": "课堂培训",
      "reset": "重设训练"
    },
    "assignment": {
      "title": "分配"
    },
    "self_study": {
      "title": "自学"
    }
  },
  "learning_path": {
    "learning_path": {
      "title": "学习路径"
    },
    "learningpaths": "学习途径",
    "title": "学习路径",
    "no_unlockers_html": "当前没有可用于解锁此学习路径的项目。请联系您的网站管理员寻求帮助",
    "knowledge_check": "知识检查",
    "essentials": "要点",
    "essential_add": "您可以在此处搜索要添加到所选基本组的要点。",
    "essentials_message": "完成解锁必需品的挑战",
    "tags": "标签",
    "see_results": "查看结果",
    "results": {
      "averages": "平均值",
      "learning_path_progress": "学习道路进度",
      "knowledge_check_scores": "知识检查分数",
      "knowledge_check_results": "知识检查结果",
      "xp_points": "Xp点",
      "details": "细节",
      "detailed": {
        "question": "题",
        "correct_answer": "正确答案",
        "correct_answers": "正确答案",
        "correct_answers_amount": "正确答案数量",
        "incorrect_answers": "答案不正确",
        "incorrect_answers_amount": "错误的答案数量",
        "no_results": "没结果",
        "current_questions": "当前问题",
        "historical_questions": "历史问题"
      },
      "groups": {
        "name": "组的名字",
        "learning_path_progress": "学习道路进度",
        "first_attempt": "第一次尝试",
        "last_attempt": "最后一次尝试",
        "achieved_learning_path": "学习路径",
        "not_applicable": "不适用",
        "groupless": "无群"
      }
    }
  },
  "badge": {
    "title": "成就解锁！",
    "points": "+%{score} 点"
  },
  "download_list": {
    "download_specifications": "下载规格书",
    "download": "下载"
  },
  "challenge": {
    "intro": {
      "title_html": "挑战",
      "test": "在挑战中测试它！",
      "explanation_html": "准备好了吗？尽量回答这些问题。你只有一次机会正确回答的问题越多，要素就越多，标记为已完成。但还有更多… <br> 每个正确答案，您都会获得 100 个 XP 积分。查看您的个人资料页面，了解您如何与同事相比排名！",
      "ready_set_go": "准备。。。设置。。。去！",
      "already_completed": "继续研究要点。",
      "retries": {
        "zero": "您再也没有尝试了",
        "one": "你有一次尝试！",
        "other": "您有 ％{count} 次尝试"
      }
    },
    "button": {
      "continue": "继续挑战",
      "start": "开始挑战"
    },
    "circle-chart": {
      "questions": "问题"
    },
    "completed": {
      "nice_try": "不错的尝试！",
      "good_job": "干得好！",
      "failed_html": "你仍然有东西要学，所以还没有完成任何必需品。<br>探索和研究以下要点：",
      "perfect_score": "您在以下主题上取得了完美的分数：",
      "marked_complete": "这些主题已被标记为完整，但无论如何，可以随意研究它们。",
      "answered_correctly_html": "您正确回答了 <b>%{correct}</b> 总计 <b>%{total}</b> 正确答案。",
      "circle-chart": {
        "your-score": "您的分数"
      },
      "button": {
        "close": "近挑战",
        "try-again": "再试一次"
      }
    },
    "question": {
      "helper_multiple_choice": "选择正确的答案",
      "helper_multiple_response": "多个答案可能",
      "question": "题"
    },
    "points": "XP 点"
  },
  "knowledge_check": {
    "attempts": {
      "first": "第一次尝试",
      "many": "试图 %{count}",
      "previous": "以前的尝试",
      "last": "最后一次尝试"
    },
    "intro": {
      "title_html": "知识检查",
      "test": "在知识检查中测试您的知识！",
      "explanation_html": "你学到了很多东西，让我们看看你知道的。此知识检查衡量您在此学习路径上的知识。<br><br>如果你正确回答了80%的问题，学习路径被标记为完整，你会得到一个奖杯.<br>别忘了。。。每个正确答案可获得 100 个 XP 积分。",
      "already_completed": "查看下一个学习路径！"
    },
    "button": {
      "continue": "继续知识检查",
      "start": "开始知识检查"
    },
    "completed": {
      "good_job": "做得好！",
      "nice_try": "太糟糕了。。。",
      "passed_check_html": "伟大！您通过此知识检查，<b>%{percentage}%</b>.<br>找出下一个学习路径！",
      "try_again": {
        "title": "再试一次",
        "text_html": "不幸的是，您获得了<b>％{percentage}％</b>的评分，并且没有通过此知识检查。明智地使用它...",
        "review": "在重新进行知识检查之前，请查看学习路径中的所有要点。",
        "good_luck": "祝你好运！"
      },
      "failed_html": "不幸的是，你试了两次，但没有通过这个知识检查。<br>您没有剩余尝试。<br>请与您的团队经理联系。",
      "button": {
        "close": "关闭知识检查"
      },
      "bonus_html": "您正确回答了超过 <b>%{percentage}%</b> 问题。您获得了<b>%{bonus_points}</b> 奖励积分！"
    },
    "report": "查看结果",
    "intro_text": "看看下面的问题，看看您回答不正确的是哪些",
    "all_answers_correct": "恭喜你！您正确回答了此知识检查中的所有问题。",
    "your_answers": "你的答案",
    "your_answer": "你的答案",
    "correct_answers": "正确答案",
    "correct_answer": "正确答案"
  },
  "feedback": {
    "send": "发送反馈",
    "input_placeholder": "请给我们您的反馈",
    "feedback_sent": "感谢您的反馈。",
    "feedback_error": "无法发送您的反馈，请重试。",
    "screenshot_prompt": "系统将提示您共享屏幕，我们将使用它来创建屏幕截图，并将其与反馈一起发送"
  },
  "single_sign_on": {
    "failure": "使用单一登录无法进行身份验证"
  }
};
